<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane
      v-for="item in tabsList"
      :key="item.name"
      :label="item.label"
      :name="item.name"
    ></el-tab-pane>
  </el-tabs>
</template>
<script>
export default {
  props: {
    tabsList: {
      type: Array,
      default: () => []
    },
    hval: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      activeName: '1'
    }
  },
  watch: {
    hval (val) {
      this.activeName = val
    }
  },
  methods: {
    handleClick (val) {
      this.$emit('change', this.activeName)
    }
  }
}
</script>
<style lang='scss'>
.el-tabs {
  padding-left: 20px;
  background: #fff;
  .el-tabs__nav-wrap::after {
    height: 0;
  }
  .el-tabs__header {
    margin: 0;
  }
}
</style>
