// ====================快递跟踪==================
export default {
  path: '/express-track',
  name: 'express-track',
  meta: {
    title: '快递跟踪'
  },
  component: () =>
    import(
      /* webpackChunkName: "express-track" */ '@/views/express-track'
    ),
  children: [
    {
      path: '/express-track/custom',
      name: 'custom-send',
      meta: {
        title: '客户寄回'
      },
      component: () =>
        import(
          /* webpackChunkName: "custom-send" */ '@/views/express-track/custom-send'
        )
    },
    {
      path: '/express-track/merchant',
      name: 'merchant-replace',
      meta: {
        title: '商家补发'
      },
      component: () =>
        import(
          /* webpackChunkName: "merchant-replace" */ '@/views/express-track/merchant-replace'
        )
    }
  ]
}
