import http from '../request/http'
import QS from 'qs'
// ============供应商===============
// 商家列表
export function supplierList (params) {
  return http.post('/mgr/statistic/list', params)
}
// 商家管理-列表
export function supplierManageList (params) {
  return http.post('/mgr/store/list', params)
}
// 商家管理-新增
export function supplierManageAdd (params) {
  return http.post('/mgr/store/add', params)
}
// 商家管理-修改
export function supplierManageEdit (params) {
  return http.post('/mgr/store/update', params)
}
// 商家管理-详情
export function supplierManageDetail (params) {
  return http.post(`/mgr/store/detail?${QS.stringify(params)}`)
}
// 商家管理-删除
export function supplierManageDel (params) {
  return http.post(`/mgr/store/delete?${QS.stringify(params)}`)
}
// 商家管理-地址列表
export function supplierAddressList (params) {
  return http.post('/mgr/store/address/list', params)
}
// 商家管理-地址新增
export function supplierAddressAdd (params) {
  return http.post('/mgr/store/address/add', params)
}
// 商家管理-地址编辑
export function supplierAddressEdit (params) {
  return http.post('/mgr/store/address/update', params)
}
// 商家管理-地址删除
export function supplierAddressDel (params) {
  return http.post(`/mgr/store/address/delete?${QS.stringify(params)}`)
}
// 商家管理-类目列表
export function supplierCategoryList (params) {
  return http.post('/mgr/store/category/list', params)
}
// 商家管理-类目新增
export function supplierCategoryAdd (params) {
  return http.post('/mgr/store/category/add', params)
}
// 商家管理-类目编辑
export function supplierCategoryEdit (params) {
  return http.post('/mgr/store/category/update', params)
}
// 商家管理-类目删除
export function supplierCategoryDel (params) {
  return http.post(`/mgr/store/category/delete?${QS.stringify(params)}`)
}
// 商家管理-人员列表
export function supplierUserList (params) {
  return http.post('/mgr/storeMember/list', params)
}
// 商家管理-人员新增
export function supplierUserAdd (params) {
  return http.post('/mgr/storeMember/add', params)
}
// 商家管理-人员修改
export function supplierUserEdit (params) {
  return http.post('/mgr/storeMember/update', params)
}
// 商家管理-人员删除
export function supplierUserDel (params) {
  return http.post(`/mgr/storeMember/delete?${QS.stringify(params)}`)
}
// 商家管理-修改默认商家
export function updateDefaultStore (params) {
  return http.post(`/mgr/storeMember/updateDefaultStore?${QS.stringify(params)}`)
}
// 商家管理-发票信息
export function supplierBillInfo (params) {
  return http.post(`/mgr/store/invoice/detail?${QS.stringify(params)}`)
}
// 商家管理-发票信息新增
export function supplierBillInfoAdd (params) {
  return http.post('/mgr/store/invoice/add', params)
}
