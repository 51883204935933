// RSA加密
import JsEncrypt from 'jsencrypt'
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCA3aKn/GhPCO2gY95XIBJhjXEP0ywDwHIfcNcXWCMk58oOe7Z02BasQDviy+gKSW3lLeWLGV2mOfwKCPZQNc+j98W5sBJalOqf3EY8hmRuq18OIBmjugYzhhxZZuDSJr5srk2588WREhLLoG9fWwtY4GZa84KzmsK2wv7ZtZCdVQIDAQAB'
const privateKey = 'MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBAIDdoqf8aE8I7aBj3lcgEmGNcQ/TLAPAch9w1xdYIyTnyg57tnTYFqxAO+LL6ApJbeUt5YsZXaY5/AoI9lA1z6P3xbmwElqU6p/cRjyGZG6rXw4gGaO6BjOGHFlm4NImvmyuTbnzxZESEsugb19bC1jgZlrzgrOawrbC/tm1kJ1VAgMBAAECgYBDC4zh22elnx6KsQrvx2bozD8Dnbmrpgd6JioyIg/+1n2AtYrDFd4N9klyAOaT24pKj2t6yFK59jcUPX9xUd3aUyQGruSyKeNFuDnXUdF1hpCojBV8srlDbaAwFeo4ZXVuKbzL6hjOdgc3ynkZ5nFpmrqfSf/s64LpmUtnAyOtjQJBALwzlybV2w7RMI0GywTPYycv2X2FfB8Fk4gTjgMe0D/xKsRWnx7UdWMmFfPHBUcM7+kXYoY4Lu1xCfBXmraIOBMCQQCvSfRTMs4Nk+pmO6uXLzJNbV71pZrb7KFKwut2imDddKS7nHiP987/dx0ESq3qb0jp0opTNtcmJ2d+FVGFmdH3AkBQmL9ocDkptUhXfMtAuhe6VnGLobiwCFFP+x/cEjwYxg0MnBP0vgOvfEX2agrGjLHJXqxg0nq2UKxy8vFcriVlAkBASFzDjiQmspIJf+SDgxltb6idaWgLMxJLeoQjasfvLvGio9VK5tQQt7v6cPCqwrj4lu+HaNsp8NVxVZwZ5LktAkA5gdPdXgUvDnjQdRUImm+BhQXnBbUzPYopUMUA3tm1QsB1rPYiAa7AfqGRCUmiAvYHxguu+c1QRklEpGqd8EPh'
// 加密方法
export function RSAencrypt (pas) {
  // 实例化jsEncrypt对象
  const jse = new JsEncrypt()
  // 设置公钥
  jse.setPublicKey(publicKey)
  return jse.encrypt(pas)
}

// 解密方法
export function RSAdecrypt (pas) {
  const jse = new JsEncrypt()
  // 私钥
  jse.setPrivateKey(privateKey)
  return jse.decrypt(pas)
}
