<!--
 * @Autor: liujun
 * @Description: 表格组件
 * @Date: 2020-03-30 11:29:27
 * @LastEditTime: 2021-01-07 16:06:35
 -->

<template>
  <div class="table_component clearfix">
    <!-- :cell-style="cellStyle" -->
    <el-table
      row-class-name="row_class_name"
      :data="data"
      :max-height="maxHeight"
      :show-header="showHeader"
      :border="border"
      :v-loading="loading"
      :class="className"
      tooltip-effect="dark"
      element-loading-text="给我一点时间"
      :stripe="stripe"
      :header-cell-style="{ color: 'rgba(0,0,0,0.85)', background: '#FAFAFA' }"
      fit
      :show-summary="showSummary"
      :default-sort="defaultSort"
      :highlight-current-row="false"
      style="width: 100%"
      @select="select"
      @select-all="selectAll"
      @row-contextmenu="testrightClick"
      @row-dblclick="rowdblclick"
      @row-click="rowClick"
      @selection-change="handleSelectionChange"
      @cell-click="cellClick"
      @sort-change="sortChange"
      :height="rowHeight"
      ref="eltable"
      @toggleAllSelection="toggleAllSelection"
    >
      <el-table-column
        label="全选"
        width="60"
        type="selection"
        v-model="data"
        fixed="left"
        align="center"
        :selectable='selectEnable'
        v-if="isShowCheckBox"
      ></el-table-column>
      <el-table-column
        label="序号"
        type="index"
        width="60"
        class-name="index_class"
        v-if="isShowIndex"
        fixed="left"
      ></el-table-column>
      <!-- 使用v-for遍历 -->
      <div v-for="(colConfig, index) in colConfigs" :key="index">
        <!-- slot 添加自定义配置项 -->
        <slot v-if="colConfig.slot" :name="colConfig.slot"></slot>
        <!-- component 特殊处理某一项 -->
        <component
          v-else-if="colConfig.component"
          :is="config.component"
          :col-config="colConfig"
        ></component>
        <!-- colConfig.tooltip  不传为false  -->
        <el-table-column
          v-else
          v-bind="colConfig"
          :align="align"
          :show-overflow-tooltip="colConfig.tooltip?true:false"
          :formatter="if_null"
        ></el-table-column>
      </div>
    </el-table>
   <div class="foot">
      <!-- 统计插槽-->
      <div class="statistics">
        <slot name="statistics">
        </slot>
      </div>
       <!-- 分页 -->
        <el-pagination
          v-if="!!total"
          class="pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          background
          @prev-click="prevClick"
          @next-click="nextClick"
          :pager-count="pagerCount"
          ref="pagination"
          :total="total"
        ></el-pagination>
   </div>

  </div>
</template>
<script>
export default {
  name: 'tableE',
  props: {
    // 排序
    defaultSort: {
      type: Object,
      default: () => {}
    },
    // 表头
    colConfigs: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 表格数据
    data: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 是否显示表头
    showHeader: {
      type: Boolean,
      default: true
    },
    // 是否显示斑马纹
    stripe: {
      type: Boolean,
      default: false
    },
    // 是否显示合计
    showSummary: {
      type: Boolean,
      default: false
    },
    // 固定表头的表格
    rowHeight: {
      type: String,
      default: null
    },
    // 是否带有纵向边框
    border: {
      type: Boolean,
      default: false
    },
    // 流体高度 超过该高度就自动有滚动条
    maxHeight: {
      type: [String, Number]
    },
    // loading效果
    loading: {
      type: Boolean,
      default: false
    },
    // 表格类名
    className: {
      type: String,
      default: ''
    },
    // 列表是否可选择
    isShowCheckBox: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'left'
    },
    // width:{
    //   type: String,
    //   default: ""
    // },
    // 列表是否显示序号
    isShowIndex: {
      type: Boolean,
      default: false
    },
    // 是否需要右键
    isrightEvent: {
      type: Boolean,
      default: false
    },
    // 是否需要双击
    isrowdblEvent: {
      type: Boolean,
      default: false
    },
    // 是否需要点击单元格
    iscellClick: {
      type: Boolean,
      default: false
    },
    // 当前页
    currentPage: {
      type: Number,
      default: 1
    },
    // 总页数
    pageSize: {
      type: Number,
      default: 1
    },
    // 总条数
    total: {
      type: Number,
      default: 0
    },
    // 当前页数量
    pagerCount: {
      type: Number,
      default: 7
    },
    // 多选框根据  返回false不能选   返回true可以选
    selectEnable: {
      type: Function
    }
  },
  computed: {
    // maxHeight () {
    //   return (
    //     (document.documentElement.clientHeight || document.body.clientHeight) -
    //     300
    //   )
    // }
  },
  methods: {
    // cellStyle ({ row, column, rowIndex, columnIndex }) {
    //   if (rowIndex % 2 === 1) {
    //     return 'background:#fff'
    //   }
    // },
    // 如果没有数据填充 '/'
    if_null (row, column, cellValue) {
      // if (cellValue === 0) {
      //   return cellValue
      // }
      return cellValue || '-'
    },
    testrightClick (row, column, event) {
      const that = this
      if (that.isrightEvent) {
        event.cancelBubble = true
        const params = {
          row: row,
          column: column,
          event: event
        }
        that.$emit('rightEvent', params)
      }
    },
    rowdblclick (row, column, event) {
      this.$emit('rowdblEvent', row, column, event)
    },
    rowClick (row, column, event) {
      this.$emit('rowClickEvent', row, column, event)
    },
    select (selection, row) {
      this.$emit('select', selection)
    },
    selectAll (selection) {
      this.$emit('selectAll', selection)
    },
    toggleAllSelection () {
      this.$emit('toggleAllSelection')
    },
    handleSelectionChange (val) {
      this.$emit('selectionChange', val)
    },
    cellClick (row, column, cell, event) {
      this.$emit('cellClick', row, column, cell)
    },
    sortChange (data) {
      this.$emit('sortChange', data)
    },
    prevClick (val) {
      this.$emit('prevClick', val)
    },
    nextClick (val) {
      this.$emit('nextClick', val)
    },
    handleSizeChange (val) {
      this.$emit('handleSizeChange', val)
    },
    handleCurrentChange (val) {
      this.$emit('handleCurrentChange', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.table_component {
  margin-top: 20px;
  position: relative;
  .row_class_name {
    height: 53px;
  }
  .foot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
  }
  .statistics {
    margin-right: 30px;
    font-weight: 700;
    font-size: 16px;
  }
}
</style>
