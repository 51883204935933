<template>
  <el-cascader
    v-model="value"
    :options="data"
    size="small"
    :props="propsParams"
    :placeholder="placeholder"
    @change="change"
    filterable
    clearable
    :disabled="disabled"
    :key="keyValue"
  >
  </el-cascader>
</template>
<script>
export default {
  props: {
    hval: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    propsParams: {
      type: Object,
      default: () => {
        return {
          expandTrigger: 'click',
          value: 'code',
          label: 'name'
        }
      }
    }
  },
  data () {
    return {
      value: [],
      keyValue: 1
    }
  },
  watch: {
    hval: {
      immediate: true,
      handler (val) {
        this.value = val
      }
    },
    // key 就变化，就重新渲染
    data (newVal) {
      this.keyValue++
    }
  },
  methods: {
    change () {
      this.$emit('change', this.value)
    }
  }
}
</script>
