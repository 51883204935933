import http from '../request/http'
import specialHttp from '../request/specialHttp'
import QS from 'qs'
// ========== 财务结算 ========

// ========== 服务站 ========
// 付款账单-列表
export function statPayBillList (params) {
  return http.post('/mgr/orderPayment/list', params)
}
// 付款账单-批量付款
export function statPayBillUpdateStatus (params) {
  return http.post('/mgr/orderPayment/updateStatus', params)
}
// 付款账单-明细
export function statPayBillDetail (params) {
  return http.post(`/mgr/auditSettlement/listByOrderPaymentId?${QS.stringify(params)}`)
}
// 付款账单-导出
export function statPayBillExport (params) {
  return specialHttp.post('/mgr/downloadExcel/downloadExcelForOrderPayment', params)
}
// ========== 商家 之后名字改 平台 ========

// 工单收款-列表
export function storeOrderPayList (params) {
  return http.post('/mgr/orderPaymentStore/list', params)
}
// 工单收款-导出
export function storeOrderPayExport (params) {
  return specialHttp.post('/mgr/downloadExcel/downloadOrderPaymentStore', params)
}
// 工单收款-申请开票
export function storeOrderApplyBill (params) {
  return http.post('/mgr/invoiceDetailsStore/add', params)
}
// 工单收款-收款
export function storeOrderCollectPay (params) {
  return http.post('/mgr/fundDetailsStore/add', params)
}
// 工单收款-收款明细(点击实收金额)
export function storeOrderCollectPayDetail (params) {
  return http.post(`/mgr/fundDetailsStore/listAll?${QS.stringify(params)}`)
}
// 工单收款-发票明细(点击已开发票)
export function storeOrderBillDetail (params) {
  return http.post(`/mgr/invoiceDetailsStore/listAll?${QS.stringify(params)}`)
}

// ========== 商家品控处罚 ========

// ========== 开票明细 ========
// 列表
export function storeBillList (params) {
  return http.post('/mgr/invoiceDetailsStore/list', params)
}
// 开票
export function storeMakeBill (params) {
  return http.post('/mgr/invoiceDetailsStore/update', params)
}
