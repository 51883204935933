import http from '../request/http'
import QS from 'qs'
// 登录
export function login (params) {
  return http.post(`/mgr/login/getToken?${QS.stringify(params)}`)
}
// 登出
export function loginOut (params) {
  return http.post('/mgr/login/logout', params)
}
// 修改密码
export function updatePsw (params) {
  return http.post(`/mgr/login/updatePassword?${QS.stringify(params)}`)
}
// 切换商家
export function toggleStore (params) {
  return http.post(`/mgr/login/updateLoginUserStore?${QS.stringify(params)}`)
}
