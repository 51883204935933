<template>
  <div>
    <div v-for="(item, index) in newMenuList" :key="index">
      <el-submenu v-if="item.children&&item.children.length" :index="item.label">
        <template slot="title">
          <!-- <i :class="item.meta.icon"></i> -->
          <span>{{ item.name }}</span>
        </template>
        <sideBarItem :menuList="item.children" />
      </el-submenu>
      <el-menu-item v-else :index="item.label" v-show="!item.hide">
        <!-- <i :class="item.meta.icon" :style="{padding:item.meta.icon? 0:'10px'}"></i> -->
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sideBarItem',
  props: {
    menuList: {
      type: Array,
      require: true
    }
  },
  components: {},
  data () {
    return {}
  },
  computed: {
    newMenuList () {
      // const arr = this.menuList.filter(item => item.meta.roles.includes(this.$store.state.role))
      return this.menuList
    }
  },
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>
<style lang='scss' scoped>
</style>
