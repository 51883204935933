import Vue from 'vue'
import Vuex from 'vuex'
import im from './modules/im'
import trtc from './modules/trtc'
import { areaTreeList } from '@/api'
import { localGet, localSet } from '@/utils/common'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginUserInfo: localGet('loginUserInfo') || {}, // 当前登录用户信息
    defaultStoreName: localGet('defaultStoreName') || '', // 当前显示的商家
    menuList: localGet('menuList') || [], // 菜单
    areaList: localGet('areaList') || [], // 省市区
    isCollapse: false, // 伸缩菜单栏
    deviceFault: false, // 订单详情是否有三日内故障
    drawerShow: false, // 订单详情侧弹窗是否显示
    IndexInfo: {}, // 点击弹出侧弹窗获取当前这条的信息为了在订单详情请求
    serialNumArr: [] // 工单详情 设备序列号集合
  },
  mutations: {
    SET_MENU_LIST: (state, list) => {
      localSet('menuList', list)
      state.menuList = list
    },
    SET_AREA_LIST: (state, list) => {
      localSet('areaList', list)
      state.areaList = list
    },
    SET_COLLAPSE: (state) => {
      state.isCollapse = !state.isCollapse
    },
    SET_DEVICE_FAULT: (state, payload) => {
      state.deviceFault = payload
    },
    SET_DRAWER_SHOW: (state, payload) => {
      state.drawerShow = payload
    },
    SET_INDEX_INFO: (state, payload) => {
      state.IndexInfo = payload
    },
    SET_LOGIN_USERINFO: (state, payload) => {
      localSet('loginUserInfo', payload)
      state.loginUserInfo = payload
    },
    SET_DEFAULT_STORENAME: (state, payload) => {
      localSet('defaultStoreName', payload)
      state.defaultStoreName = payload
    },
    SET_SERIAL_NUM: (state, payload) => {
      state.serialNumArr = payload
    }
  },
  actions: {
    AREA_LIST: ({ commit }, list) => {
      return new Promise((resolve, reject) => {
        areaTreeList().then(res => {
          if (res.code === '1') {
            commit('SET_AREA_LIST', res.data)
            resolve()
          }
        })
      })
    }
  },
  modules: {
    im,
    trtc
  }
})
