<template>
<div>
  <div style="
        color:#C4C4C4;
        font-size: 14px;
        font-family:PingFang SC;
        font-weight:500;
        background: #fff;
        position:absolute;
        z-index: 1999;
        top:0;
        left: 0;
        bottom: 0;
        right:0;">
        <span style="color:#1B1C29;font-weight:700;position:absolute;top:20px;left: 15px;fontSize:18px">{{title}}</span>
    <div style="
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    display: inline-block;
    position: relative;
    left: 50%;
    top: 50%;
    ">暂无数据～</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title']
}
</script>

<style>

</style>
