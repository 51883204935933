// ====================订单系统==================
export default {
  path: '/order-system',
  name: 'order-system',
  meta: {
    title: '订单系统'
  },
  component: () =>
    import(/* webpackChunkName: "order-system" */ '@/views/order-system'),
  children: [
    {
      path: '/order-system/list',
      name: 'order-list',
      meta: {
        title: '订单列表'
      },
      component: () =>
        import(
          /* webpackChunkName: "order-system" */ '@/views/order-system/order-list'
        )
    },
    {
      path: '/order-system/chargeback',
      name: 'chargeback',
      meta: {
        title: '客户退单'
      },
      component: () =>
        import(
          /* webpackChunkName: "chargeback" */ '@/views/order-system/chargeback'
        )
    },
    {
      path: '/order-system/compensate',
      name: 'compensate',
      meta: {
        title: '客户补偿'
      },
      component: () =>
        import(
          /* webpackChunkName: "compensate" */ '@/views/order-system/compensate'
        )
    },
    {
      path: '/order-system/error-order',
      name: 'error-order',
      meta: {
        title: '有误订单'
      },
      component: () =>
        import(
          /* webpackChunkName: "error-order" */ '@/views/order-system/error-order'
        )
    },
    {
      path: '/order-system/list-detail',
      name: 'order-list-detail',
      meta: {
        title: '订单列表详情',
        activeMenu: '/order-system/list'
      },
      component: () =>
        import(
          /* webpackChunkName: "order-list-detail" */ '@/views/order-system/order-list/detail'
        )
    }
  ]
}
