// 用于订单系统接口  主要是请求外部接口
import axios from 'axios'
import { baseURLToOrder } from '@/api/baseURL'
// import router from '../router'
import { Message } from 'element-ui'
const instance = axios.create({
  baseURL: baseURLToOrder,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = localStorage.getItem('token') || ''
    return config
  },
  (err) => Promise.reject(err)
)
instance.interceptors.response.use(
  (response) => {
    const res = response.data
    if (response.status === 200) {
      if (res.code !== 0) {
        Message.error(res.msg)
        return Promise.reject(res)
      }
      return res
    }
  },
  (error) => {
    return Promise.reject(error.response)
  }
)

export default instance
