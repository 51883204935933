import moment from 'moment'
// 格式化时间
export function formatDate (date, format = 'YYYY-MM-DD') {
  return moment(date).format(format)
}
// 距离今天的前后几天/月的日期
/**
 *
 * @param {*} day 前后几天 负是前 正是后
 * @param {*} type days 天  weeks周 months月
 * @param {*} isFormat  是否格式化
 * @returns 结果
 */
export function diffDate (day, type = 'days', isFormat = false) {
  return isFormat ? moment().add(day, type).format('YYYY-MM-DD') : moment().add(day, type)._d
}
// 根据获取的省市区拼接code数组
export function handleArea (province, city, region) {
  if (province && city && region) {
    return [province, city, region]
  }
  if (province && city) {
    return [province, city]
  }
  if (province) {
    return [province]
  }
}
// 根据多个坐标经纬度获取到中心点的经纬度
export function getCenter (pointArray) {
  var sortedLongitudeArray = pointArray.map(item => item.longitude).sort()// 首先对经度进行排序，红色部分是array中经度的名称
  var sortedLatitudeArray = pointArray.map(item => item.latitude).sort()// 对纬度进行排序，红色部分是array中纬度的名称
  var centerLongitude = ((parseFloat(sortedLongitudeArray[0]) + parseFloat(sortedLongitudeArray[sortedLongitudeArray.length - 1])) / 2).toFixed(4)
  const centerLatitude = ((parseFloat(sortedLatitudeArray[0]) + parseFloat(sortedLatitudeArray[sortedLatitudeArray.length - 1])) / 2).toFixed(4)

  // 如果经纬度在array中不是数字类型，需要转化为数字类型进行计算，如果是可以去掉parseFloat处理
  return [centerLongitude, centerLatitude]
}

// 数组中对象去重
export function removeRepeat (arr, name) {
  const obj = {}
  const peon = arr.reduce((cur, next) => {
    // eslint-disable-next-line no-unused-expressions
    obj[next[name]] ? '' : obj[next[name]] = true && cur.push(next)
    return cur
  }, []) // 设置cur默认类型为数组，并且初始值为空的数组
  return peon
}
// 节流函数
/**
 *
 * @param {*} fn 被执行的函数
 * @param {*} interval 隔间时间
 */
export function throttle (fn, interval = 1000, option) {
  var last = 0
  var timer = null
  if (!option) option = {}

  var trailing = option.trailing || false
  var result = option.result || null

  var handleFn = function () {
    // this和argument
    var _this = this
    var _arguments = arguments

    var now = new Date().getTime()
    if (now - last > interval) {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      callFn(_this, _arguments)
      last = now
    } else if (timer === null && trailing) { // 只是最后一次
      timer = setTimeout(function () {
        timer = null
        callFn(_this, _arguments)
      }, interval)
    }
  }

  handleFn.cancel = function () {
    clearTimeout(timer)
    timer = null
  }

  function callFn (context, argument) {
    var res = fn.apply(context, argument)
    if (result) {
      result(res)
    }
  }

  return handleFn
}
// 防抖函数
/**
 *
 * @param {*} fn 被执行的函数
 * @param {*} delay 间隔时间
 * @param {*} leading 是否立即执行
 * @returns
 */
export function debounce (fn, delay = 1000, leading = true) {
  var timer = null
  leading = leading || false
  var handleFn = function () {
    if (timer) clearTimeout(timer)
    // 获取this和argument
    var _this = this
    var _arguments = arguments
    if (leading) {
      // 通过一个变量来记录是否立即执行
      var isInvoke = false
      if (!timer) {
        fn.apply(_this, _arguments)
        isInvoke = true
      }
      // 定时器通过修改timer来修改instant
      timer = setTimeout(function () {
        timer = null
        if (!isInvoke) {
          fn.apply(_this, _arguments)
        }
      }, delay)
    } else {
      timer = setTimeout(function () {
        // 在执行时，通过apply来使用_this和_arguments
        fn.apply(_this, _arguments)
      }, delay)
    }
  }

  // 取消处理
  handleFn.cancel = function () {
    if (timer) clearTimeout(timer)
  }

  return handleFn
}
// 本地缓存获取
export function localGet (key) {
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (error) {
    return value
  }
}
// 本地缓存设置
export function localSet (key, value) {
  window.localStorage.setItem(key, JSON.stringify(value))
}
// 本地缓存移除
export function localRemove (key) {
  window.localStorage.removeItem(key)
}

// =============用于输入价格校验========
export const checkPrice = (rule, val, cb) => {
  val = val + ''
  var Reg = /^(\d+)\.?(\d*)$/
  if (Reg.test(val)) {
    if (val.includes('.')) {
      val.replace(Reg, function ($1, $2, $3) {
        if ($3.length > 2) {
          cb(new Error('小数点后只能输入两位'))
        }
        return cb()
      })
    } else if (val.includes('-')) {
      cb(new Error('不能输入负数'))
    } else {
      return cb()
    }
  } else {
    cb(new Error('只能输入数字和小数点'))
  }
}
// 解决两个数相乘出现浮点数bug
export function handleMulFloat (num1, num2) {
  const num1String = num1.toString()
  const num2String = num2.toString()
  const num1Digits = (num1String.split('.')[1] || '').length
  const num2Digits = (num2String.split('.')[1] || '').length
  const baseNum = Math.pow(10, num1Digits + num2Digits)
  return Number(num1String.replace('.', '')) * Number(num2String.replace('.', '')) / baseNum
}
// 处理订单状态
export function orderStatus (val) {
  let html = ''
  switch (val) {
    case 0:
      html = '初始化待付款'
      break
    case 1:
      html = '待配送'
      break
    case 2:
      html = '租赁中'
      break
    case 3:
      html = '租赁完成'
      break
    case 4:
      // html = '用户取消订单'
      html = '已关闭'
      break
    case 5:
      // html = '订单过期系统自动取消'
      html = '已关闭'
      break
    case 6:
      // html = '用户删除订单'
      html = '已关闭'
      break
    case 7:
      html = '支付待财务确认'
      break
    case 8:
      html = '待配货'
      break
    case 11:
      html = '待初审'
      break
    case 12:
      html = '已关闭'
      // html = '审核不通过'
      break
    case 14:
      html = '设备已回收待后台清算'
      break
    case 15:
      html = '用户待付款(订单清算完毕)'
      break
    case 16:
      html = '待资方审核'
      break
    case 17:
      html = '面签'
      break
    case 18:
      html = '订单复审状态(一审成功后)'
      break
    case 19:
      html = '云玳/云块处理中'
      break
    case 20:
      html = '订单/汇票审核不通过待取消'
      break
    case 100:
      html = '风控确认订单出险'
      break
    case 101:
      html = '风控确认订单出险101'
      break
    case 102:
      html = '风控确认订单出险102'
      break
    case 30:
      html = '合同待签署'
      break
  }
  return html
}
