import httpToOrder from '../request/httpToOrder'
import http from '../request/http'
import specialHttp from '../request/specialHttp'
import QS from 'qs'
// =============个人中心-导出任务=========
// 列表
export function exportTaskList (params) {
  return http.post('/mgr/downloadTaskTable/list', params)
}
// 删除
export function exportTaskDel (params) {
  return http.post(`/mgr/downloadTaskTable/delete?${QS.stringify(params)}`)
}
// 下载
export function exportTaskDownload (params) {
  return http.post(`/mgr/downloadFile/downloadFile?${QS.stringify(params)}`)
}
// ==============订单系统=============
// 工单详情-是否存在配件订单
export function judgePartsOrder (params) {
  return httpToOrder.get(
    '/ecyps/afterSales/queryAfterSalesOrderAccessoryInfo',
    { params }
  )
}
// 订单系统-订单列表
export function orderList (params) {
  return httpToOrder.post('/ecyps/afterSales/afterSalesOrderLists', params)
}
// 订单系统-订单下设备序列号列表接口
export function serialByOrderId (params) {
  return httpToOrder.get('/ecyps/afterSales/orderSerialNoLists', { params })
}
// 订单系统-详情
export function orderDetail (params) {
  return httpToOrder.get('/ecyps/afterSales/orderDetailInfo', { params })
}
// 订单系统-详情-备注信息
export function orderDetailRemark (params) {
  return httpToOrder.get('/ecyps/afterSales/queryOrderRemarkInfoList', {
    params
  })
}
// 订单系统-详情-设备序列号
export function orderDetailSerial (params) {
  return httpToOrder.get('/ecyps/afterSales/orderDeviceDetailInfo', { params })
}
// 订单系统-详情-支付信息
export function orderDetailPay (params) {
  return httpToOrder.get('/ecyps/afterSales/orderBills', { params })
}
// 订单系统-详情-发货物流
export function orderDetailShip (params) {
  return httpToOrder.get('/ecyps/afterSales/orderDeliveryLogisticsInfo', {
    params
  })
}
// 订单系统-详情-回收物流
export function orderDetailRecycle (params) {
  return httpToOrder.get(
    '/ecyps/afterSales/orderRecyclingDeliveryLogisticsInfo',
    { params }
  )
}
// 订单系统-商品类目接口
export function goodsCategoryList () {
  return httpToOrder.get('/ecyps/afterSales/goodsCategoryLists')
}
// 订单系统—获取地址、联系人、电话
export function orderInfo (params) {
  return httpToOrder.get('/ecyps/afterSales/orderAddressInfo', { params })
}
// 订单系统-商家信息
export function partnerInfoLists (params) {
  return httpToOrder.post('/ecyps/afterSales/partnerInfoLists', params)
}
// 订单系统-订单报修
export function orderRepairs (params) {
  return http.post('/mgr/postSaleOrder/add', params)
}
// 订单报修及新增序列号 校验序列号是否有未完成的
export function validateSerialNum (params) {
  return http.post(
    `/mgr/postSaleOrder/hasUnfinishedOrder?${QS.stringify(params)}`
  )
}
// 根据商品编号获取业务类型-新增报修售后
export function getOrderTypeBySkuId (params) {
  return http.post(`/mgr/skuInfo/getTypteBySKUId?${QS.stringify(params)}`)
}
// 订单系统-查看是否有正在处理的报障工单（点击报修弹出提示信息）
export function checkHaveHandleOrder (params) {
  return http.post(`/mgr/postSaleOrder/handlingOrder?${QS.stringify(params)}`)
}
// 订单系统-商家管理模式提醒（点击报修弹出提示信息）
export function storeModeWarn (params) {
  return http.post('/mgr/store/remind', params)
}
// ================客户退单=============
// 列表
export function chargebackList (params) {
  return http.post('/mgr/presaleOrderChargebackInfo/query', params)
}
// 修改
export function chargebackUpdate (params) {
  return http.post('/mgr/presaleOrderChargebackInfo/update', params)
}
// ================客户补偿=============
export function compensateList (params) {
  return http.post('/mgr/presaleOrderCompensateInfo/query', params)
}
export function compensateAdd (params) {
  return http.post('/mgr/presaleOrderCompensateInfo/add', params)
}
export function compensateEdit (params) {
  return http.post('/mgr/presaleOrderCompensateInfo/update', params)
}
// ================有误订单=============
export function errorOrderList (params) {
  return http.post('/mgr/presaleOrderFaultInfo/query', params)
}
export function errorOrderAdd (params) {
  return http.post('/mgr/presaleOrderFaultInfo/add', params)
}
export function errorOrderEdit (params) {
  return http.post('/mgr/presaleOrderFaultInfo/update', params)
}
// ================工单系统=============
// 工单列表
export function workOrderList (params) {
  return http.post('/mgr/postSaleOrder/list', params)
}
// 工单列表导出(导出设备)
export function workOrderExport (params) {
  return specialHttp.post(
    '/mgr/downloadExcel/downloadExcelForPostSaleOrder',
    params
  )
}
// 工单列表导出(导出设备对应的工单)
export function deviceToOrderExport (params) {
  return specialHttp.post(
    '/mgr/downloadExcel/downloadExcelForPostSaleOrderAll',
    params
  )
}
// 工单列表-工单导出(跟进工单)
export function followOrderExport (params) {
  return specialHttp.post(
    '/mgr/downloadExcel/downloadExcelForPostSaleOrderIng',
    params
  )
}
// 工单列表-工单导出(历史工单)
export function historyOrderExport (params) {
  return specialHttp.post(
    '/mgr/downloadExcel/downloadExcelForPostSaleOrderFinished',
    params
  )
}
// 工单列表-工单费用导出(历史工单)
export function historyOrderPayExport (params) {
  return specialHttp.post(
    '/mgr/downloadExcel/downloadExcelForHistoryOrder',
    params
  )
}
// 工单列表 - 置顶和取消置顶
export function workOrderTop (params) {
  return http.post(`/mgr/postSaleOrder/updateTop?${QS.stringify(params)}`)
}
// 工单列表-销售查单
export function saleQueryOrderList (params) {
  return http.post('/mgr/postSaleOrderFinished/saleQuery', params)
}
// 查询工单状态
export function searchOrderStatus (params) {
  return http.post(`/mgr/postSaleOrder/getOrderStatus?${QS.stringify(params)}`)
}

// 工单列表 - 指派回访（客服回访页面）
export function batchDispatchVisit (params) {
  return http.post('/mgr/postSaleOrder/batchUpdateVisitService', params)
}
// 工单列表 - 批量无需回访（客服回访页面）
export function batchNoVisit (params) {
  return http.post('/mgr/serviceVisit/batchNotVisit', params)
}
// 工单列表 - 历史工单(删除状态恢复操作)
export function overOrderRestore (params) {
  return http.post(`/mgr/postSaleOrder/recovery?${QS.stringify(params)}`)
}
// 工单系统-客服回访-领取
export function receiverToVist (params) {
  return http.post(
    `/mgr/postSaleOrder/updateVisitService?${QS.stringify(params)}`
  )
}
// 工单列表-历史工单
export function workOrderListToHistory (params) {
  return http.post('/mgr/postSaleOrderFinished/list', params)
}
// 工单详情
export function workOrderDetail (params) {
  return http.post(`/mgr/postSaleOrder/detail?${QS.stringify(params)}`)
}
// 工单更新--更改正常和紧急状态
export function workOrderUpdate (params) {
  return http.post('/mgr/postSaleOrder/update', params)
}
// 工单详情-客户投诉
export function customerComplaint (params) {
  return http.post(`/mgr/postSaleOrder/complaint?${QS.stringify(params)}`)
}
// 工单详情-客户催促
export function customerUrge (params) {
  return http.post(`/mgr/postSaleOrder/customerUrge?${QS.stringify(params)}`)
}
// 工单详情-修改工单类型
export function orderTypeUpdate (params) {
  return http.post(
    `/mgr/postSaleOrder/changeOrderTypeChi?${QS.stringify(params)}`
  )
}
// 工单详情-是否客情单
export function guestSentimentUpdate (params) {
  return http.post(`/mgr/postSaleOrder/guestSentiment?${QS.stringify(params)}`)
}
// 工单详情-更新手机号及地址
export function updateMobileAndAddress (params) {
  return http.post('/mgr/postSaleOrder/updateOrderMobileAndAddress', params)
}
// 远程处理-更新状态 领取
export function workOrderUpdateStatus (params) {
  return http.post(`/mgr/postSaleOrder/updateStatus?${QS.stringify(params)}`)
}
// 远程处理和上门处理-完成转客服回访(新)
export function completeReturnVisit (params) {
  return http.post(`/mgr/postSaleOrder/toVisit?${QS.stringify(params)}`)
}
// 工单详情-取消工单 删除工单
export function workOrderCancel (params) {
  return http.post(`/mgr/postSaleOrder/delete?${QS.stringify(params)}`)
}
// 工单详情-转派功能
export function workOrderTransfer (params) {
  return http.post(
    `/mgr/postSaleOrder/updateAndTransfer?${QS.stringify(params)}`
  )
}
// 工单详情-转交功能
export function workOrderDeliver (params) {
  return http.post(
    `/mgr/postSaleOrder/updateAndDeliver?${QS.stringify(params)}`
  )
}
// ============ 设备信息 ==========
// 工单详情-设备信息列表
export function devicesInfoList (params) {
  return http.post('/mgr/orderEquipmentItem/list', params)
}
// 工单详情-设备信息修改
export function devicesInfoUpdate (params) {
  return http.post('/mgr/orderEquipmentItem/update', params)
}
// 工单详情-设备序列号编辑
export function devicesSerialEdit (params) {
  return http.post(
    `/mgr/orderEquipmentItem/updateSerialNum?${QS.stringify(params)}`
  )
}
// 工单详情-设备序列号清空
export function devicesSerialClear (params) {
  return http.post(
    `/mgr/orderEquipmentItem/updateSerialNumEmpty?${QS.stringify(params)}`
  )
}
// 工单详情-设备信息批量修改
export function devicesInfoBatchUpdate (params) {
  return http.post('/mgr/orderEquipmentItem/batchUpdate', params)
}
// 工单详情-远程处理-快速响应和快速处理
export function fastHandleAndRes (params) {
  return http.post(`/mgr/logMessage/addLogPrescription?${QS.stringify(params)}`)
}
// 工单详情-设备信息新增(序列号新增)
export function devicesInfoAdd (params) {
  return http.post('/mgr/orderEquipmentItem/batchAdd', params)
}
// 工单详情-设备列表删除
export function devicesInfoDel (params) {
  return http.post(`/mgr/orderEquipmentItem/delete?${QS.stringify(params)}`)
}
// ============ 备注信息 ==========
// 工单详情-获取备注信息
export function remarkList (params) {
  return http.post('/mgr/logMessage/selectLogs', params)
}
// 工单详情-备注新增
export function remarkAdd (params) {
  return http.post(`/mgr/logMessage/addItemRemark?${QS.stringify(params)}`)
}
// 工单详情-备注编辑
export function remarkEdit (params) {
  return http.post(`/mgr/logMessage/updateItemRemark?${QS.stringify(params)}`)
}
// 工单详情-备注删除
export function remarkDel (params) {
  return http.post(`/mgr/logMessage/delItemRemark?${QS.stringify(params)}`)
}
// ============ 故障图 ==========
// 工单详情-故障图新增
export function faultImgAdd (params) {
  return http.post('/mgr/attachment/add', params)
}
// 工单详情-故障图删除
export function faultImgDel (params) {
  return http.post(`/mgr/attachment/delete?${QS.stringify(params)}`)
}
// ============ 快递信息 ==========
// 工单详情-快递新增
export function expressAdd (params) {
  return http.post('/mgr/expressInfo/add', params)
}
// 工单详情-根据快递单号查询快递公司
export function expressCompanyByCode (params) {
  return http.post(
    `/mgr/expressInfo/getNameByExpressCode?${QS.stringify(params)}`
  )
}
// 工单详情-快递编辑
export function expressEdit (params) {
  return http.post('/mgr/expressInfo/update', params)
}
// 工单详情-根据工单得到所有快递信息
export function expressListByOrder (params) {
  return http.post(`/mgr/expressInfo/selectAllByOrder?${QS.stringify(params)}`)
}
// 客户寄回、商家补发列表
export function expressList (params) {
  return http.post('/mgr/expressInfo/list', params)
}
// 客户寄回、商家补发列表导出
export function expressExport (params) {
  return specialHttp.post('/mgr/downloadExcel/downloadStoreExpressList', params)
}
// 快递删除
export function expressDel (params) {
  return http.post(`/mgr/expressInfo/delete?${QS.stringify(params)}`)
}
// 快递取消
export function expressCancel (params) {
  return http.post(`/mgr/expressInfo/cancel?${QS.stringify(params)}`)
}
// 快递刷新
export function expressRefresh (params) {
  return http.post(`/mgr/expressInfo/syncExpressByCode?${QS.stringify(params)}`)
}
// ============ 上门处理 ==========
// 上门处理列表
export function sceneList (params) {
  return http.post('/mgr/visitOrder/list', params)
}
// 上门处理列表导出
export function sceneExport (params) {
  return specialHttp.post(
    '/mgr/downloadExcel/downloadExcelForVisitOrder',
    params
  )
}
// 获取基础信息来实现二次上门
export function getInfoToSecondScene (params) {
  return http.post(`/mgr/postSaleOrder/detailSimple?${QS.stringify(params)}`)
}
// 上门处理详情
export function sceneDetail (params) {
  return http.post(`/mgr/visitOrder/detail?${QS.stringify(params)}`)
}
// 初始化上门单（再次派单） 改派服务站
export function sceneReset (params) {
  return http.post(`/mgr/visitOrder/initOrder?${QS.stringify(params)}`)
}
// 上门处理修改
export function sceneEdit (params) {
  return http.post('/mgr/visitOrder/update', params)
}
// 上门处理-二次上门
export function secondScene (params) {
  return http.post(
    `/mgr/postSaleOrderFinished/secendTimeOrder?${QS.stringify(params)}`
  )
}
// 上门处理修改预约时间
export function sceneUpdateTime (params) {
  return http.post('/mgr/visitOrder/updateAppointmentVisitTime', params)
}
// 上门处理更新状态
export function sceneUpdateStatus (params) {
  return http.post('/mgr/visitOrder/updateStatus', params)
}
// 上门处理中止、上门中止、验收
export function scenenOverAndAcceptance (params) {
  return http.post(`/mgr/visitOrder/checkAndAccept?${QS.stringify(params)}`)
}
// 上门处理费用列表
export function scenePriceList (params) {
  return http.post(`/mgr/visitOrderCost/costsByVisit?${QS.stringify(params)}`)
}
// 上门处理费用新增
export function scenePriceAdd (params) {
  return http.post('/mgr/visitOrderCost/add', params)
}
// 上门处理费用编辑
export function scenePriceEdit (params) {
  return http.post('/mgr/visitOrderCost/update', params)
}
// 上门处理费用删除
export function scenePriceDel (params) {
  return http.post(`/mgr/visitOrderCost/delete?${QS.stringify(params)}`)
}
// 上门单的派单服务站列表（上门处理发单、派单列表）
export function sendOrderList (params) {
  return http.post('/mgr/srviceStation/listForVisit', params)
}
// 上门处理发单、派单列表 发单功能
export function sendOrder (params) {
  return http.post('/mgr/visitOrderResponse/addList', params)
}
// 上门处理发单、派单列表 派单功能
export function assignOrder (params) {
  return http.post(
    `/mgr/visitOrder/selectedSrviceStation?${QS.stringify(params)}`
  )
}

// ==========处理日志==========
export function handleLogList (params) {
  return http.post(`/mgr/logMessage/getLogsByOrderId?${QS.stringify(params)}`)
}
export function handleLogAdd (params) {
  return http.post(`/mgr/logMessage/addOrderRemark?${QS.stringify(params)}`)
}
// ==========相关工单==========
// 报修工单
export function serviceVisitAboutOrder (params) {
  return http.post(`/mgr/serviceVisit/correlationOrder?${QS.stringify(params)}`)
}
// 报修设备
export function aboutOrderToDevice (params) {
  return http.post(
    '/mgr/orderEquipmentItem/correlationOrderEquipmentItem',
    params
  )
}
// 报修统计
export function aboutOrderToStatic (params) {
  return http.post('/mgr/orderEquipmentItem/correlationOrderStatistics', params)
}
// ===========客户回访=========
// 详情
export function serviceVisitDeatail (params) {
  return http.post(`/mgr/serviceVisit/detail?${QS.stringify(params)}`)
}
// 新增(提交回访)
export function serviceVisitAdd (params) {
  return http.post('/mgr/serviceVisit/add', params)
}
// 无需回访
export function serviceVisitNoAdd (params) {
  return http.post(`/mgr/serviceVisit/notAdd?${QS.stringify(params)}`)
}
// 指派回访客服
export function assignVisitService (params) {
  return http.post(
    `/mgr/postSaleOrder/updateVisitServiceInDetail?${QS.stringify(params)}`
  )
}
// ===========接单管理===========（上门工单派单响应信息接口）
// 列表
export function acceptOrderList (params) {
  return http.post('/mgr/visitOrderResponse/list', params)
}
// 读取
export function acceptOrderRead (params) {
  return http.post(`/mgr/visitOrderResponse/read?${QS.stringify(params)}`)
}
// 接单
export function acceptOrderAccept (params) {
  return http.post(`/mgr/visitOrderResponse/receiving?${QS.stringify(params)}`)
}
// ==========上门要求==============
// 新增
export function serviceNoteAdd (params) {
  return http.post('/mgr/visitOrderRequirement/add', params)
}
// 列表
export function serviceNoteList (params) {
  return http.post(`/mgr/visitOrderRequirement/listAll?${QS.stringify(params)}`)
}
// 修改
export function serviceNoteEdit (params) {
  return http.post('/mgr/visitOrderRequirement/update', params)
}
// 删除
export function serviceNoteDel (params) {
  return http.post(`/mgr/visitOrderRequirement/delete?${QS.stringify(params)}`)
}
