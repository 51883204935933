import breadcrumb from './breadcrumb'
import btnComp from './btnComp'
import tableComp from './tableComp'
import inputComp from './inputComp'
import btnGroup from './btnGroup'
import dialogComp from './dialogComp'
import inputFormComp from './inputFormComp'
import selectComp from './selectComp'
import selectFormComp from './selectFormComp'
import radioFormComp from './radioFormComp'
import tabsComp from './tabsComp'
import dateComp from './dateComp'
import uploadimg from './uploadimg'
import notData from './notData'
import cascader from './cascader'
const obj = {}
obj.install = function (Vue) {
  Vue.component('breadcrumb', breadcrumb)
  Vue.component('btnComp', btnComp)
  Vue.component('tableComp', tableComp)
  Vue.component('inputComp', inputComp)
  Vue.component('btnGroup', btnGroup)
  Vue.component('dialogComp', dialogComp)
  Vue.component('inputFormComp', inputFormComp)
  Vue.component('selectComp', selectComp)
  Vue.component('selectFormComp', selectFormComp)
  Vue.component('radioFormComp', radioFormComp)
  Vue.component('tabsComp', tabsComp)
  Vue.component('dateComp', dateComp)
  Vue.component('uploadimg', uploadimg)
  Vue.component('notData', notData)
  Vue.component('cascader', cascader)
}
export default obj
