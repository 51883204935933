// ====================品控管理==================
export default {
  path: '/qc-manage',
  name: 'qc-manage',
  meta: {
    title: '品控管理'
  },
  component: () =>
    import(
      /* webpackChunkName: "qc-manage" */ '@/views/qc-manage'
    ),
  children: [
    {
      path: '/qc-manage/duty',
      name: 'qc-manage-duty',
      meta: {
        title: '品控定责'
      },
      component: () =>
        import(
        /* webpackChunkName: "duty" */ '@/views/qc-manage/duty'
        )
    },
    {
      path: '/qc-manage/check',
      name: 'qc-manage-check',
      meta: {
        title: '品控复查'
      },
      component: () =>
        import(
        /* webpackChunkName: "check" */ '@/views/qc-manage/check'
        )
    },

    {
      path: '/qc-manage/service-cost',
      name: 'service-cost',
      meta: {
        title: '售后成本审核'
      },
      component: () =>
        import(
          /* webpackChunkName: "service-cost" */ '@/views/qc-manage/service-cost'
        )
    }
  ]
}
