<template>
    <div class="msg_bell">
      <div class="title">消息通知</div>
      <template v-if="unReadMsgList.length > 0">
        <ul class="message_list">
        <li class="item" v-for="(item,index) in unReadMsgList" :key="index" @click="onClickMsgItem(item)">
          你所在的群聊<span style="color:#ff6700">{{item.groupId}}</span>有未读消息，请点击查看
        </li>
      </ul>
      </template>
      <div v-else style="color: #999;">暂无消息</div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapState('im', ['unReadMsgList'])
  },
  mounted () {
    this.getUnReadMsgList()
  },
  methods: {
    ...mapMutations('im', ['changeUnReadMsgList']),
    ...mapActions('im', ['getUnReadMsgList']),
    onClickMsgItem (row) {
      // 区分如果是在就是在group-chat的页面，就是传事件过去，如不是就直接跳转
      if (this.$route.name === 'group-chat') {
        this.$bus.$emit('onClickMsgItem', {
          groupId: row.groupId,
          orderId: row.orderId
        })
      } else {
        this.$router.push({
          name: 'group-chat',
          params: {
            groupId: row.groupId,
            orderId: row.orderId
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
  .msg_bell {
    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .message_list {
      max-height: 200px;
      overflow-y: scroll;
      li {
        padding: 10px;
        border-bottom: 1px solid #ddd;
        color: #999;
        cursor: pointer;
      }
    }
  }
</style>
