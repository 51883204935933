import http from '../request/http'
import QS from 'qs'

// ============ 角色管理 ==============
// 角色列表
export function rolesList (params) {
  return http.post('/mgr/sysRole/list', params)
}
// 角色新增
export function rolesAdd (params) {
  return http.post('/mgr/sysRole/add', params)
}
// 角色编辑
export function rolesEdit (params) {
  return http.post('/mgr/sysRole/update', params)
}
// 角色更新状态
export function rolesUpdateStatus (params) {
  return http.post(`/mgr/sysRole/updateStatus?${QS.stringify(params)}`)
}
// 角色获取权限
export function permissionByRole (params) {
  return http.post(`/mgr/rolePermission/permissionByRole?${QS.stringify(params)}`)
}
// 角色获取权限保存
export function savePermissionByRole (params) {
  return http.post('/mgr/rolePermission/savePermissionForRole', params)
}

// ============ 人员管理 ==============
// 人员列表
export function userList (params) {
  return http.post('/mgr/user/list', params)
}
// 人员新增
export function userAdd (params) {
  return http.post('/mgr/user/add', params)
}
// 修改用户信息
export function userEdit (params) {
  return http.post('/mgr/user/update', params)
}
// 用户详情
export function userDetail (params) {
  return http.post(`/mgr/user/detail?${QS.stringify(params)}`)
}
// 用户批量锁定，删除，恢复
export function userUpdateStatus (params) {
  return http.post('/mgr/user/updateStatus', params)
}

//  ==========菜单==========
// 菜单列表
export function menuList (params) {
  return http.post('/mgr/permission/listAll', params)
}
// 菜单新增
export function menuAdd (params) {
  return http.post('/mgr/permission/add', params)
}
// 菜单编辑
export function menuEdit (params) {
  return http.post('/mgr/permission/update', params)
}
// 菜单删除
export function menuDel (params) {
  return http.post(`/mgr/permission/delete?${QS.stringify(params)}`)
}

//  ==========参数字典==========
// 字典列表
export function dictList (params) {
  return http.post('/mgr/configTable/listAll', params)
}
// 字典新增
export function dictAdd (params) {
  return http.post('/mgr/configTable/add', params)
}
// 字典删除
export function dictDel (params) {
  return http.post(`/mgr/configTable/delete?${QS.stringify(params)}`)
}
/* // 字典详情
export function dictDetail (params) {
  return http.post(`/mgr/configTable/detail?${QS.stringify(params)}`)
} */
// 字典修改
export function dictEdit (params) {
  return http.post('/mgr/configTable/update', params)
}
// 获取字典树形结构数据
export function dictThree (params) {
  return http.post(`/mgr/configTable/treeList?${QS.stringify(params)}`)
}
//  ==========设备故障==========
// 设备故障列表
export function deviceFaultList (params) {
  return http.post('/mgr/configFault/list', params)
}
// 设备故障新增
export function deviceFaultAdd (params) {
  return http.post('/mgr/configFault/add', params)
}
// 设备故障修改
export function deviceFaultEdit (params) {
  return http.post('/mgr/configFault/update', params)
}
// 设备故障删除
export function deviceFaultDel (params) {
  return http.post('/mgr/configFault/delete', params)
}
// 设备故障现象树形结构
export function deviceFaulThree (params) {
  return http.post(`/mgr/configFault/treeList?${QS.stringify(params)}`)
}
//  ==========售后值班==========
// 批量新增
export function dutyBatchAdd (params) {
  return http.post('/mgr/afterSaleScheduling/batchAdd', params)
}
// 新增
export function dutyAdd (params) {
  return http.post('/mgr/afterSaleScheduling/add', params)
}
// 列表
export function dutyList (params) {
  return http.post('/mgr/afterSaleScheduling/listAllForWeek', params)
}
// 是否自动排班
export function dictIsAuto (params) {
  return http.post(`/mgr/afterSaleScheduling/autoScheduling?${QS.stringify(params)}`)
}
// 删除排班人员
export function dutyUserDel (params) {
  return http.post(`/mgr/afterSaleScheduling/delete?${QS.stringify(params)}`)
}
