const OSS = require('ali-oss')
export const client = new OSS({
  region: 'oss-cn-hangzhou',
  accessKeyId: 'LTAI5t5kqiHruptFa6wsdE76',
  accessKeySecret: 'BDjDPcTSR0CZAJ5LdPOaFWfgc357ci',
  bucket: 'test-zgl'
  // secure: true // https上传
})

// 存储目录
// 正式环境图片目录
export const catelogImg = 'after-sale-img/'
// 测试环境图片目录
export const catelogImgTest = 'after-sale-img-test/'
// 正式环境视频目录
export const catelogVideo = 'after-sale-video/'
// 测试环境视频目录
export const catelogVideoTest = 'after-sale-video-test/'

// oss地址
export const ossURL = 'http://test-zgl.oss-cn-hangzhou.aliyuncs.com/'

// 生成uuid 用于生成图片名
export function uuid () {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  var uuid = s.join('')
  return uuid
}
