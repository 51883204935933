import { render, staticRenderFns } from "./uploadimg.vue?vue&type=template&id=3539f206&scoped=true&"
import script from "./uploadimg.vue?vue&type=script&lang=js&"
export * from "./uploadimg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3539f206",
  null
  
)

export default component.exports