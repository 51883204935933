<template>
  <el-aside :width="`${$store.state.isCollapse?'0px':'200px'}`">
    <div class="logo">
      <img src="~@/assets/images/logo.png" alt="">
    </div>
    <el-menu
      :default-active="defaultActive"
      background-color="#fff"
      text-color="#333"
      active-text-color="#1F84FA"
      :collapse="$store.state.isCollapse"
      router
    >
      <sideBarItem :menuList="menuList2"/>
    </el-menu>
  </el-aside>
</template>
<script>
import sideBarItem from './sideBarItem'
// import { routes } from '@/router'
import { mapState } from 'vuex'
export default {
  name: 'sideBar',
  props: {},
  components: { sideBarItem },
  data () {
    return {
      firstMenu: ''
    }
  },
  computed: {
    ...mapState(['menuList']),
    // defaultActive: {
    //   get () {
    //     return this.$route.path
    //   },
    //   set (v) {
    //     return v
    //   }
    // },
    defaultActive () {
      const route = this.$route
      const { meta, path } = route
      // 解决跳转详情  菜单栏高亮
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    menuList2 () {
      const menu = JSON.parse(JSON.stringify(this.menuList))
      this.handleMenu(menu)
      // if (this.$route.path === '/') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.$router.push(menu[0] && menu[0].label)
      // }
      return menu
    }
  },
  created () {},
  mounted () {},
  methods: {
    handleMenu (menu) {
      menu.forEach(item => {
        if (item.children) {
          if (item.children.length > 0 && item.children[0].type === 1) {
            this.handleMenu(item.children)
          } else {
            item.children = []
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.logo {
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #385BFF;
  img {
    height: 40px;
    vertical-align: middle;
  }
}
</style>
