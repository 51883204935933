<template>
  <el-form-item :label="label" :prop="prop">
    <el-radio-group :disabled="disabled" v-model="radio" @change="change">
      <el-radio
        v-for="(item, index) in radioList"
        :key="index"
        :label="item.value"
        >{{ item.label }}</el-radio
      >
    </el-radio-group>
  </el-form-item>
</template>
<script>
export default {
  name: '',
  props: {
    prop: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hval: {
      type: [String, Number, Boolean]
    },
    radioList: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data () {
    return {
      radio: this.typeofHval ? 1 : true
    }
  },
  computed: {
    typeofHval () {
      return typeof this.hval === 'number'
    }
  },
  watch: {
    hval: {
      immediate: true,
      handler (val) {
        this.radio = val
      }
    }
  },
  created () {},
  mounted () {},
  methods: {
    change () {
      this.$emit('change', this.radio)
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
