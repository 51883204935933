import tim from '@/tim'
import TIM from 'tim-js-sdk'
import Vue from 'vue'
import trtcCalling from '@/trtc-calling'
// import { Message } from 'element-ui'
import { enterChat, getUserSig, unReadMessageList, destroyGroup } from '@/api'
import { localSet } from '@/utils/common'
import { SDKAppID } from '@/api/baseURL'
export default {
  namespaced: true,
  state: {
    SDKAppID,
    userSig: '',
    enterChatInfo: {},
    currentMessageList: [],
    currentGroupId: '',
    chatLoading: false, // 请求数据loading
    nextReqMessageID: '',
    isCompleted: false, // 当前会话消息列表是否已经拉完了所有消息
    newMsgGroup: [], // 存储新消息的groupId
    unReadNewMsg: false, // 是否收到新消息
    unReadMsgList: [] // 未读消息列表
  },
  mutations: {
    changeNewMsgGroup (state, id) {
      if (!state.newMsgGroup.includes(id)) {
        state.newMsgGroup.push(id)
      }
    },
    removeNewMsgGroup (state, id) {
      const index = state.newMsgGroup.findIndex(item => item === id)
      if (index !== -1) {
        state.newMsgGroup.splice(index, 1)
      }
    },
    changeUserSig (state, payload) {
      state.enterChatInfo = payload
    },
    changeUnReadNewMsg (state, payload) {
      state.unReadNewMsg = payload
    },
    changeUnReadMsgList (state, payload) {
      state.unReadMsgList = payload
    },
    // 从后台获取的聊天信息
    changeEnterChatInfo (state, payload) {
      state.enterChatInfo = payload
    },
    // 发送新消息放到当前聊天list
    pushCurrentMessageList (state, data) {
      if (Array.isArray(data)) {
        // 筛选出当前会话的消息
        const result = data.filter(
          item => item.conversationID === `GROUP${state.enterChatInfo.groupId}`
        )
        state.currentMessageList = [...state.currentMessageList, ...result]
      } else if (
        data.conversationID === `GROUP${state.enterChatInfo.groupId}`
      ) {
        state.currentMessageList = [...state.currentMessageList, data]
      }
    },
    // 获取聊天数据
    changeMessageList (state, payload) {
      // 更新messageID，续拉时要用到
      state.nextReqMessageID = payload.nextReqMessageID
      state.isCompleted = payload.isCompleted
      // 过滤掉 视频通话的im信息
      const data = payload.messageList.filter(
        item => item.type !== 'TIMCustomElem'
      )
      // 更新当前消息列表，从头部插入
      state.currentMessageList = [...data, ...state.currentMessageList]
    },
    // 点击当前的GroupId
    changeCurrentGroupId (state, currentGroupId) {
      state.currentGroupId = currentGroupId
    },
    // 请求loading
    changeChatLoading (state, isLoading) {
      state.chatLoading = isLoading
    },
    // 重置数据
    resetIMState (state) {
      Object.assign(state, {
        enterChatInfo: {},
        currentMessageList: [],
        nextReqMessageID: '',
        isCompleted: false,
        currentGroupId: ''
      })
    }
  },
  actions: {
    // 获取铃铛消息未读列表
    async getUnReadMsgList ({ commit, rootState }) {
      const params = {
        userId: rootState.loginUserInfo.id
      }
      const res = await unReadMessageList(params)
      commit('changeUnReadMsgList', res.data)
      commit('changeUnReadNewMsg', res.data.length > 0)
    },
    // 进入聊天
    enterChatAction ({ commit, dispatch }, orderId) {
      return new Promise((resolve, reject) => {
        commit('changeChatLoading', true)
        enterChat({ orderId })
          .then(res => {
            commit('resetIMState')
            commit('changeChatLoading', false)
            resolve(res.data)
            localSet('memberList', res.data.groupIdMemberInfoList)
            commit('changeEnterChatInfo', res.data)
            commit('changeCurrentGroupId', res.data.groupId)
            commit('removeNewMsgGroup', res.data.groupId)
            dispatch('joinGroupAction', res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // 加入群组
    joinGroupAction ({ dispatch }, res) {
      tim
        .joinGroup({
          groupID: res.data.groupId,
          type: TIM.TYPES.GRP_MEETING
        })
        .then(_ => {
          dispatch('getMessageList', `GROUP${res.data.groupId}`)
        })
    },
    // 解散群组
    destroyGroupAction ({ commit }, orderId) {
      destroyGroup({ orderId })
    },
    getMessageList (context, conversationID) {
      // if (context.state.isCompleted) {
      //   Message.info('已经没有更多的历史消息了哦')
      //   return
      // }
      return new Promise(resolve => {
        const { nextReqMessageID } = context.state
        tim
          .getMessageList({ conversationID, nextReqMessageID, count: 15 })
          .then(imReponse => {
            context.commit('changeMessageList', imReponse.data)
            Vue.prototype.$bus.$emit('scrollMessageListToButtom')
            resolve()
          })
      })
    },
    async imLogin ({ commit, rootState }) {
      const userId = rootState.loginUserInfo.id
      const res = await getUserSig({ userId })
      const userSig = res.data
      commit('changeUserSig', userSig)
      await tim.login({
        userID: userId,
        userSig
      })
      await trtcCalling.login({
        userID: userId,
        userSig
      })
    },
    async imLogout () {
      await tim.logout()
      await trtcCalling.logout()
    }
  },
  getters: {
    isHaveChatInfo (state) {
      return Object.keys(state.enterChatInfo).length !== 0
    },
    groupId (state) {
      return state.enterChatInfo.groupId
    },
    memberList (state) {
      return state.enterChatInfo.groupIdMemberInfoList
    },
    groupList (state) {
      return state.enterChatInfo.userGroupList
    },
    remoteUser (state) {
      return state.enterChatInfo.remoteUser
    },
    userId (state, getters, rootState) {
      return rootState.loginUserInfo.id
    }
  }
}
