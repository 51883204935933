import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/components/layout'
// import login from '@/views/login'
import dataCenter from './routes/data-center'
import userCenter from './routes/user-center'
import orderSystem from './routes/order-system'
import workOrder from './routes/work-order'
import expressTrack from './routes/express-track'
import serviceStation from './routes/service-station'
import financial from './routes/financial'
import supplier from './routes/supplier'
import systemManage from './routes/system-manage'
import eventManage from './routes/event-manage'
import qcManage from './routes/qc-manage'
Vue.use(VueRouter)
// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch(err => err)
}
export const routes = [
  {
    path: '/',
    name: 'layout',
    component: layout,
    meta: {},
    children: [
      dataCenter,
      userCenter,
      orderSystem,
      workOrder,
      expressTrack,
      serviceStation,
      financial,
      supplier,
      systemManage,
      eventManage,
      qcManage
      // {
      //   path: '/accept-order',
      //   name: 'accept-order',
      //   meta: {
      //     title: '接单管理',
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "accept-order" */ '@/views/accept-order'
      //     )
      // },
    ]
  },
  // 用于售前的crm系统查单
  {
    path: '/crm',
    meta: {
      title: '售前查单'
    },
    component: () =>
      import(
        /* webpackChunkName: "crm" */ '@/views/crm'
      )
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/login')
    // component: login
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
