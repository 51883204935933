// 用于导出列表   只需发送请求  不需要关心是否有响应
import axios from 'axios'
import { baseURL } from '@/api/baseURL'
const instance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = localStorage.getItem('token') || ''
    return config
  },
  (err) => Promise.reject(err)
)

export default instance
