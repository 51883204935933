<template>
  <dialogComp title="修改密码" :isShow="isShow" @close="close" @sure="sure">
    <el-form :model="formData" :rules="rules" ref="formData" label-width="100px">
      <inputFormComp
        label="原密码:"
        prop="oldPass"
        :hval="formData.oldPass"
        @blur="oldPassBlur"
        placeholder="请输入原密码"
      />
      <inputFormComp
        label="新密码:"
        prop="newPass"
        :hval="formData.newPass"
        @blur="newPassBlur"
        placeholder="请输入新密码"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import { updatePsw } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data () {
    return {
      formData: {
        oldPass: '',
        newPass: ''
      },
      rules: {
        oldPass: [
          { required: true, message: '请输入原密码', trigger: 'blur' }
        ],
        newPass: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    oldPassBlur (val) {
      this.formData.oldPass = val
    },
    newPassBlur (val) {
      this.formData.newPass = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const { oldPass, newPass } = this.formData
          updatePsw({ oldPass, newPass }).then(res => {
            if (res.code === '1') {
              this.$message.success('修改成功')
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>
