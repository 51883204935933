<template>
<div>
   <el-input
      :type="inputType"
      v-model.trim="value"
      :min="min"
      @blur="blur"
      @input='input'
      @keyup.enter.native="enter"
      @focus="focus"
      @clear="clear"
      :placeholder="placeholder"
      :prefix-icon="prefixIcon"
      :readonly="readonly"
      :suffix-icon="suffixIcon"
      :disabled="disabled"
      :clearable="clearable"
      :size="size"
    >
     <i slot="prefix" class="el-input__icon el-icon-search" v-if="isShowIcon"></i>
    </el-input>
</div>

</template>
<script>
export default {
  props: {
    // 输入框类型 text，textarea 和其他 原生 input 的 type 值
    inputType: {
      type: String,
      default: ''
    },
    // icon
    icon: {
      type: String,
      default: ''
    },
    // 占位文本
    placeholder: {
      type: String,
      default: ''
    },
    // 输入框头部图标
    prefixIcon: {
      type: String,
      default: ''
    },
    // 输入框尾部图标
    suffixIcon: {
      type: String,
      default: ''
    },
    // 是否只读
    readonly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    // 设置最小值
    min: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'small'
    },
    hval: {
      type: [String, Number],
      default: ''
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    isShowIcon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: ''
    }
  },
  watch: {
    hval (val) {
      this.value = val
    }
  },
  methods: {
    blur () {
      this.$emit('blur', this.value)
    },
    input () {
      this.$emit('input', this.value)
    },
    focus () {
      this.$emit('focus', this.value)
    },
    clear () {
      this.$emit('blur', this.value)
    },
    click () {
      this.$emit('click', this.value)
    },
    enter () {
      this.$emit('enter', this.value)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
