<template>
  <div class="dialog">
    <el-dialog
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :before-close="close"
      :width="width"
      :title="title"
      :top="top"
      :modal="modal"
    >
      <div class="content" :style="{maxHeight:maxHeight}">
        <slot></slot>
      </div>
      <div slot="footer" v-show="isShowFooter">
        <btnComp @click="close" btnType="">{{cancelText}}</btnComp>
        <btnComp
          @click="sure"
          :loading="loading"
          >{{ confirmText }}</btnComp
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { throttle } from '@/utils/common'
export default {
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    isShowFooter: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '540px'
    },
    maxHeight: {
      type: String,
      default: '540px'
    },
    confirmText: {
      type: String,
      default: '确 定'
    },
    cancelText: {
      type: String,
      default: '取 消'
    },
    loading: {
      type: Boolean,
      default: false
    },
    // 是否需要遮罩层
    modal: {
      type: Boolean,
      default: true
    },
    top: {
      type: String,
      default: '15vh'
    }
  },
  data () {
    return {}
  },
  created () {},
  mounted () {},
  methods: {
    close () {
      this.$emit('close')
    },
    // 对确定按钮进行节流  防止用户多次点击
    sure: throttle(function () {
      this.$emit('sure')
    }, 3000),
    submit () {
      this.$emit('submit')
    }
  }
}
</script>
<style lang="scss" scoped>
  ::v-deep .el-dialog {
    .el-dialog__header {
      border-bottom: 1px solid #DFDFE6;
      .el-dialog__title {
        color: #323236;
        font-size: 16px;
      }
    }
    .el-dialog__body {
      padding: 20px 38px;
      // max-height: 450px;
      overflow-y: scroll;

    }
  }
</style>
