import tim from './tim'
import TRTCCalling from 'trtc-calling-js'
import { SDKAppID } from '@/api/baseURL'
const options = {
  SDKAppID,
  tim: tim
}

const trtcCalling = new TRTCCalling(options)

// 4 无日志级别 0有 4无
trtcCalling.setLogLevel(4)

export default trtcCalling
