import http from '../request/http'
import QS from 'qs'
// 获取省市区
export function areaList (params) {
  return http.post(`/mgr/area/detail?${QS.stringify(params)}`)
}
// 获取省市区树形
export function areaTreeList () {
  return http.post('/mgr/area/allTree')
}
// 服务站列表
export function serviceStatList (params) {
  return http.post('/mgr/srviceStation/query', params)
}
// 服务站新增
export function serviceStatAdd (params) {
  return http.post('/mgr/srviceStation/addServiceStationAndMember', params)
}
// 服务站删除
export function serviceStatDel (params) {
  return http.post(`/mgr/srviceStation/delete?${QS.stringify(params)}`)
}
// 服务站详情
export function serviceStatDeatil (params) {
  return http.post(`/mgr/srviceStation/detail?${QS.stringify(params)}`)
}
// 服务站更新、编辑
export function serviceStatUpdate (params) {
  return http.post('/mgr/srviceStation/update', params)
}
// 服务站人员列表
export function serStatPeoList (params) {
  return http.post('/mgr/serviceStationStaff/list', params)
}
// 服务站人员新增
export function serStatPeoAdd (params) {
  return http.post('/mgr/serviceStationStaff/add', params)
}
// 服务站人员修改
export function serStatPeoEdit (params) {
  return http.post('/mgr/serviceStationStaff/update', params)
}
// 服务站人员批量删除、恢复
export function serStatPeoUpdateStatus (params) {
  return http.post('/mgr/serviceStationStaff/updateStatus', params)
}

// ============服务站覆盖区域=========
// 新增
export function coverAreaAdd (params) {
  return http.post('/mgr/serviceStationCover/add', params)
}
// 删除
export function coverAreaDel (params) {
  return http.post(`/mgr/serviceStationCover/delete?${QS.stringify(params)}`)
}
// 区域覆盖列表
export function coverAreaList (params) {
  return http.post(`/mgr/serviceStationCover/listAll?${QS.stringify(params)}`)
}
// ============上门成本审核=========
// 列表
export function statOrderAuditList (params) {
  return http.post('/mgr/auditSettlement/list', params)
}
// 批量审核及结算
export function statOrderAuditUpdateStatus (params) {
  return http.post('/mgr/auditSettlement/updateStatus', params)
}
