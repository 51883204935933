<template>
  <div class='btn_group'>
      <el-button type="primary" size="small" @click="search">筛 选</el-button>
     <el-button type="plain" size="small" @click="reset">重 置</el-button>
  </div>
</template>
<script>
export default {
  name: 'btn_group',
  props: {},
  components: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {
    search () {
      this.$emit('search')
    },
    reset () {
      this.$emit('reset')
    }
  }
}
</script>
<style lang='scss' scoped>
.btn_group {
  display: flex;
}
</style>
