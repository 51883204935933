<template>
  <el-date-picker
      v-model="date"
      :type="type"
      size="small"
      range-separator="-"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      @change="dateChange"
      :clearable="false"
      style="width:270px"
    >
    </el-date-picker>
</template>

<script>
import { formatDate } from '@/utils/common'
export default {
  props: {
    type: {
      type: String,
      default: 'daterange'
    },
    hval: {
      type: Array,
      default: () => []
    },
    startPlaceholder: {
      type: String,
      default: '开始日期'
    },
    endPlaceholder: {
      type: String,
      default: '结束日期'
    }
  },
  data () {
    return {
      date: []
    }
  },
  watch: {
    hval (val) {
      this.date = val
    }
  },
  methods: {
    dateChange (val) {
      if (val) {
        const startDate = formatDate(val[0])
        const endDate = formatDate(val[1])
        this.$emit('change', startDate, endDate)
      }
    }
  }
}
</script>

<style>

</style>
