import http from '../request/http'
import QS from 'qs'
// ==========数据中心============

// SKU统计/发货报障信息导出
export function skuExportData (params) {
  return http.post('/mgr/downloadExcel/downloadExcelSKUStatistics', params)
}
// 地图
export function mapData (params) {
  return http.post('/mgr/statistic/areaDistributionInfo', params)
}
// ==========售后单王============
// 售后单王统计
export function saleRankData (params) {
  return http.post('/mgr/statistic/getAfterSalesInfo', params)
}
// ==========sku============
// 设备报修统计
export function deviceRepair (params) {
  return http.post('/mgr/statistic/equipmentRepairList', params)
}
// SKU统计
export function skuData (params) {
  return http.post('/mgr/statistic/getSKUStatistics', params)
}
// ==========工单总时效============
export function allAging (params) {
  return http.post(`/mgr/statistic/getTotalPrescriptionList?${QS.stringify(params)}`)
}
// ==========工单时效============
export function aging (params) {
  return http.post(`/mgr/statistic/getPrescriptionList?${QS.stringify(params)}`)
}
// =========开箱不良图表及统计============
// 城市开箱不良折线图
export function unpackBadEcharts (params) {
  return http.post('/mgr/statistic/poorPackBrokenLine', params)
}
// 商家开箱不良统计
export function unpackBadStat (params) {
  return http.post('/mgr/statistic/storePoorPackList', params)
}
// 商家发货排行
export function storeGoodRank (params) {
  return http.post(`/mgr/store/shipData/storeRanking?${QS.stringify(params)}`)
}
// 商家开箱不良统计-发货明细
export function deliverDetailList (params) {
  return http.post('/mgr/store/shipData/storeDeliverGoodsDetailList', params)
}
// 商家开箱不良统计-发货明细-点击开箱不良获取明细
export function packCountDetail (params) {
  return http.post(`/mgr/store/shipData/getPoorUnpackingByOrderCode?${QS.stringify(params)}`)
}
// 商家开箱不良统计-问题分析
export function problemAnalysis (params) {
  return http.post('/mgr/statistic/getEquipmentAnalysis', params)
}
// 战区接口
export function salesAreaData () {
  return http.post('/mgr/salesArea/allTree')
}
// 客户开箱不良
export function customerUnpackList (params) {
  return http.post('/mgr/statistic/customerPoorPackList', params)
}
// ============报障周期分析=============
export function faultCycleAnalysis (params) {
  return http.post('/mgr/statistic/reportingPeriodAnalysis', params)
}
// ============月度新增退租=============
export function monthRentWithdrawal (params) {
  return http.post('/mgr/statistic/newRentWithdrawalMonthly', params)
}
// ============客户退单统计=============
export function chargebackStatistic (params) {
  return http.post('/mgr/statistic/rentWithdrawalBrokenLine', params)
}
// 客户退单统计-退单原因
export function chargebackReason (params) {
  return http.post('/mgr/statistic/rentWithdrawalReason', params)
}
// 客户退单统计-退机机型
export function chargebackModel (params) {
  return http.post('/mgr/statistic/rentWithdrawalCommodity', params)
}
// 客户退单统计-退单时长
export function chargebackDuration (params) {
  return http.post('/mgr/statistic/cycleRentWithdrawal', params)
}
// 客户退单统计-表格视图统计
export function chargebackTable (params) {
  return http.post('/mgr/statistic/rentWithdrawal', params)
}
