// ====================事件管理==================
export default {
  path: '/event-manage',
  name: 'event-manage',
  meta: {
    title: '事件管理'
  },
  component: () =>
    import(
      /* webpackChunkName: "event-manage" */ '@/views/event-manage'
    ),
  children: [
    {
      path: '/event-manage/waring',
      name: 'waring-event',
      meta: {
        title: '告警事件'
      },
      component: () =>
        import(
        /* webpackChunkName: "waring-event" */ '@/views/event-manage/waring'
        )
    }
  ]
}
