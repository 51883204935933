import TIM from 'tim-js-sdk'
import TIMUploadPlugin from 'tim-upload-plugin'
import { SDKAppID } from '@/api/baseURL'
// 初始化 SDK 实例

const tim = TIM.create({
  SDKAppID
})

window.setLogLevel = tim.setLogLevel

// 无日志级别 0有 1无
tim.setLogLevel(1)

// 注册 cos
tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin })
export default tim
