
<template>
  <el-form-item :label="label" :prop="prop">
    <el-select
      @change="change"
      @input="input"
      :multiple="multiple"
      collapse-tags
      :filterable="isfilterable"
      v-model="value"
      :clearable="clearable"
      :disabled="disabled"
      :placeholder="placeholder"
      :size="size"
    >
      <el-option key="" value="" label="全部" v-if="isShowAll"></el-option>
       <el-option
        v-for="item in data"
        :key="item[optionsParams.value]"
        :label="item[optionsParams.label]"
        :value="item[optionsParams.value]"
      ></el-option>
    </el-select>
  </el-form-item>
</template>
<script>
export default {
  props: {
    // 可以配置label  value
    optionsParams: {
      type: Object,
      default: () => ({ value: 'value', label: 'label' })
    },
    prop: {
      type: String,
      default: ''
    },
    // form-item 标题
    label: {
      type: String,
      default: ''
    },
    // 是否支持模糊查询
    isfilterable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isShowAll: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    // 传入数据
    data: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'small'
    },
    hval: {
      type: [String, Number, Boolean]
    }
  },
  data () {
    return {
      value: ''
    }
  },
  watch: {
    hval: {
      immediate: true,
      handler (val) {
        this.value = val
      }
    }
  },
  mounted () {},
  methods: {
    change () {
      this.$emit('change', this.value)
    },
    clear () {
      this.$emit('change', this.value)
    },
    input () {
      this.$emit('input', this.value)
    }
  }
}
</script>
