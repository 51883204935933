// 品控管理
import http from '../request/http'
import QS from 'qs'
// =============品控定责================
// 列表
export function qcList (params) {
  return http.post('/mgr/auditSettlementStoreQualityControl/list', params)
}
// 列表导出
export function qcListExport (params) {
  return http.post('/mgr/downloadExcel/downloadExcelForQualityControl', params)
}
// 批量结算
export function qcBatchSettle (params) {
  return http.post('/mgr/auditSettlementStoreQualityControl/batchUpdateStatus', params)
}
// 反复查、反审核
export function reverseCheckOrAudit (params) {
  return http.post(`/mgr/auditSettlementStoreQualityControl/reAuditSettlement?${QS.stringify(params)}`)
}
// =============品控复查================
// 列表
export function qcCheckList (params) {
  return http.post('/mgr/orderEquipmentItem/qualityList', params)
}
// 批量修改 开箱不良
export function qcCheckBatchUpdate (params) {
  return http.post('/mgr/orderEquipmentItem/batchUpdatePoorUnpacking', params)
}
// 批量复查
export function qcCheckBatchCheck (params) {
  return http.post('/mgr/orderEquipmentItem/equipmentItemListReview', params)
}
// =============tab中的品控管理================
// tab中的品控管理列表
export function qcTabList (params) {
  return http.post('/mgr/orderEquipmentExtendItem/listByOrderId', params)
}
// tab中的品控管理-保存
export function qcTabSave (params) {
  return http.post('/mgr/orderEquipmentExtendItem/update', params)
}
// tab中的品控管理-批量取消差异提醒
export function batchCancleDiff (params) {
  return http.post('/mgr/orderEquipmentExtendItem/batchUpdateDataSyn', params)
}
// tab中的品控管理-备注新增
export function qcTabRemarkAdd (params) {
  return http.post(`/mgr/logMessage/addQualityItemRemark?${QS.stringify(params)}`)
}
// tab中的品控管理-复查、审核、商家确认
export function qcCheckAuditSure (params) {
  return http.post(`/mgr/auditSettlementStoreQualityControl/updateStatus?${QS.stringify(params)}`)
}
// =============售后成本审核================
// 工单审核-列表
export function storeOrderAuditList (params) {
  return http.post('/mgr/auditSettlementStore/list', params)
}
// 工单审核-批量审核及结算
export function storeOrderAuditUpdateStatus (params) {
  return http.post('/mgr/auditSettlementStore/updateStatus', params)
}
// 工单审核-改平台托管，改商家自管
export function storeOrderAuditChangeType (params) {
  return http.post('/mgr/auditSettlementStore/updataStoreSettlementType', params)
}
// 工单审核-改后台成本
export function updateBackCost (params) {
  return http.post(`/mgr/auditSettlementStore/updataManagerPrice?${QS.stringify(params)}`)
}
// 工单审核-同步上门成本
export function syncSceneCost (params) {
  return http.post(`/mgr/auditSettlementStore/updataVisitPrice?${QS.stringify(params)}`)
}
