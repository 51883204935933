let baseURL = ''
let baseURLToOrder = '' // boss系统请求地址i
let baseURLToCrm = '' // crm系统请求地址
let SDKAppID
switch (process.env.VUE_APP_CURRENTMODE) {
  case 'dev':
    baseURL = ''
    baseURLToOrder = ''
    baseURLToCrm = ''
    SDKAppID = 1400568251
    break
  case 'test':
    baseURL = 'http://service.zhizugz.com/api'
    baseURLToOrder = 'http://thirdparty.zhizugz.com'
    baseURLToCrm = 'http://crm-api.zhizugz.com'
    // baseURLToOrder = 'http://47.97.117.225:8066'
    // baseURLToCrm = 'http://47.97.117.225:8100'
    SDKAppID = 1400568251
    break
  case 'prod':
    baseURL = 'https://service.zugeqifu.cn/api'
    baseURLToOrder = 'https://thirdparty.zugeqifu.com'
    baseURLToCrm = 'https://crm-api.zugeqifu.com'
    SDKAppID = 1400568251
    break
  default:
    baseURL = 'https://wx.zugeqifu.cn/api'
    baseURLToOrder = 'http://47.97.117.225:8055'
    baseURLToCrm = 'http://47.97.117.225:8100'
    SDKAppID = 1400555763
}

export { baseURL, baseURLToOrder, baseURLToCrm, SDKAppID }
