<template>
  <el-breadcrumb separator="/">
    <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
    <el-breadcrumb-item
      v-for="(item,index) in list"
      :key="item.path"
      :to="{ path: item.path }"
      :style="{pointerEvents: index === 0 ?'none':'auto'}"
      >{{ item.meta.title }}</el-breadcrumb-item
    >
  </el-breadcrumb>
</template>
<script>
export default {
  name: '',
  props: {},
  components: {},
  data () {
    return {
      list2: []
    }
  },
  computed: {
    list () {
      const arr = this.$route.matched.filter(item => item.path !== '')
      // 特殊处理商家详情  要显示商家名
      arr.forEach(item => {
        if (item.meta.title === '商家详情') {
          item.meta.title = `商家详情（${this.$route.query.storeName}）`
        }
      })
      return arr
    }
  },
  /* watch: {
    $route (to, from) {
      // 为了特殊处理工单详情
      // eslint-disable-next-line no-unused-vars
      let arr = []
      if (to.meta.title === '工单详情') {
        const curarr = from.matched.filter(item => item.path !== '')
        arr = [...curarr, to]
      } else {
        arr = to.matched.filter(item => item.path !== '')
      }
      this.list2 = arr.map(item => ({ title: item.meta.title, path: item.path }))
      localStorage.setItem('curBread', JSON.stringify(this.list2))
    }
  }, */
  created () {},
  /*  mounted () {
    this.list2 = JSON.parse(localStorage.getItem('curBread'))
  }, */
  methods: {}
}
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
  background-color: #fff;
}
</style>
