// ====================个人中心==================

export default {
  path: '/user-center',
  name: 'user-center',
  meta: {
    title: '个人中心'
  },
  component: () =>
    import(
      /* webpackChunkName: "user-center" */ '@/views/user-center'
    ),
  children: [
    {
      path: '/user-center/follow',
      name: 'user-center-follow',
      meta: {
        title: '跟进工单'
      },
      component: () =>
        import(
          /* webpackChunkName: "follow" */ '@/views/user-center/follow'
        )
    },
    {
      path: '/user-center/history',
      name: 'user-center-history',
      meta: {
        title: '历史工单'
      },
      component: () =>
        import(
          /* webpackChunkName: "history" */ '@/views/user-center/history'
        )
    },
    {
      path: '/user-center/visit',
      name: 'user-center-visit',
      meta: {
        title: '我的回访'
      },
      component: () =>
        import(
          /* webpackChunkName: "visit" */ '@/views/user-center/visit'
        )
    },
    {
      path: '/user-center/scene',
      name: 'user-center-scene',
      meta: {
        title: '我的上门'
      },
      component: () =>
        import(
          /* webpackChunkName: "scene" */ '@/views/user-center/scene'
        )
    },
    {
      path: '/user-center/express',
      name: 'user-center-express',
      meta: {
        title: '我的快递'
      },
      component: () =>
        import(
          /* webpackChunkName: "express" */ '@/views/user-center/express'
        )
    },
    {
      path: '/user-center/export',
      name: 'user-center-export',
      meta: {
        title: '导出任务'
      },
      component: () =>
        import(
          /* webpackChunkName: "export-task" */ '@/views/user-center/export-task'
        )
    }
  ]
}
