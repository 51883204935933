// 主要是请求内部接口
import axios from 'axios'
import { baseURL } from '@/api/baseURL'
import router from '@/router'
import { Message } from 'element-ui'
import { RSAencrypt } from '@/utils/rsa'
import { uuid } from '@/utils/alioss'
const instance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})
// 处理加密参数
const handleEncryptParams = () => {
  const keyUuid = uuid()
  const keyUuid3 = keyUuid + keyUuid.slice(-3) // uuid + 它的最后三位来 加密
  const keyEncrypt = RSAencrypt(keyUuid3)
  return {
    keyUuid,
    keyEncrypt
  }
}
// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    const path = router.app.$route.path
    if (path === '/crm') {
      // 处理请求的api（处理crm跳转过来的api, mgr修改为foreign，并且请求头加上加密参数）
      config.url = config.url.replace(/mgr/, 'foreign')
      const { keyUuid, keyEncrypt } = handleEncryptParams()
      config.headers['key-uuid'] = keyUuid
      config.headers['key-encrypt'] = keyEncrypt
    } else {
      config.headers.Authorization = localStorage.getItem('token') || ''
    }
    return config
  },
  (err) => Promise.reject(err)
)
// 做个标识  防止出现多个message的error弹窗  foreign
let flag = true
// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    const res = response.data
    if (response.status === 200) {
      if (res.code !== '1') {
        if (!flag) return
        setTimeout(() => {
          Message.error(res.msg)
          flag = true
        }, 300)
        flag = false
        if (res.code === '040000') {
          localStorage.clear()
          router.push({ path: '/login' })
        }
        return Promise.reject(res)
      }
      return res
    }
  },
  (error) => {
    return Promise.reject(error.response)
  }
)

export default instance
