import Vue from 'vue'
import {
  Container,
  Header,
  Main,
  Footer,
  Aside,
  Menu,
  MenuItem,
  MenuItemGroup,
  Submenu,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Input,
  Button,
  Message,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  TableColumn,
  Dialog,
  Pagination,
  Tree,
  Select,
  Option,
  Radio,
  RadioGroup,
  Cascader,
  Tabs,
  TabPane,
  Upload,
  Image,
  DatePicker,
  Checkbox,
  CheckboxGroup,
  Loading,
  Tag,
  Timeline,
  TimelineItem,
  RadioButton,
  Switch,
  Tooltip,
  Drawer,
  Col,
  Row,
  Popover
} from 'element-ui'
const arr = [
  Container,
  Header,
  Main,
  Footer,
  Aside,
  Menu,
  MenuItem,
  MenuItemGroup,
  Submenu,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Input,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  TableColumn,
  Dialog,
  Pagination,
  Tree,
  Select,
  Option,
  Radio,
  RadioGroup,
  Cascader,
  Tabs,
  TabPane,
  Upload,
  Image,
  DatePicker,
  Checkbox,
  CheckboxGroup,
  Tag,
  Timeline,
  TimelineItem,
  RadioButton,
  Switch,
  Tooltip,
  Drawer,
  Col,
  Row,
  Popover
]
arr.forEach(key => {
  Vue.use(key)
})
Vue.use(Loading.directive)
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service
