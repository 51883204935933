<template>
 <el-form-item :label="label" :prop="prop">
    <el-input
      :style="{width:width}"
      :type="inputType"
      v-model.trim="value"
      :min="min"
      @blur="blur"
      @input='input'
      @focus="focus"
      @clear="clear"
      :clearable="clearable"
      :placeholder="placeholder"
      :prefix-icon="prefixIcon"
      :readonly="readonly"
      :suffix-icon="suffixIcon"
      :disabled="disabled"
      :size="size"
      :autosize="autosize"
    >
    <template slot="append" v-if="unit">{{unit}}</template>
    </el-input>
    <!-- <el-input v-show="false"></el-input> -->
    <span>{{endCon}}</span>
  </el-form-item>
</template>
<script>
export default {
  props: {
    // form-item prop校验
    prop: {
      type: String,
      default: ''
    },
    // form-item 标题
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'small'
    },
    // 输入框类型 text，textarea 和其他 原生 input 的 type 值
    inputType: {
      type: String,
      default: ''
    },
    // 父组件传过的值
    hval: {
      type: [String, Number]
      // default:""
    },
    width: {
      type: String,
      default: ''
    },
    // 占位文本
    placeholder: {
      type: String,
      default: ''
    },
    // 输入框头部图标
    prefixIcon: {
      type: String,
      default: ''
    },
    // 输入框尾部图标
    suffixIcon: {
      type: String,
      default: ''
    },
    // 是否只读
    readonly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    // 设置最小值
    min: {
      type: String,
      default: '0'
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 尾部内容
    endCon: {
      type: String,
      default: ''
    },
    // 带单位
    unit: {
      type: String,
      default: ''
    },
    // textarea 高度
    autosize: {
      type: Object,
      default: () => ({ minRows: 2, maxRows: 6 })
    }
  },
  watch: {
    hval: {
      immediate: true,
      handler: function (val) {
        this.value = val
      }
    }
  },
  data () {
    return {
      value: ''
    }
  },
  filters: {

  },
  methods: {
    blur () {
      this.$emit('blur', this.value)
    },
    input () {
      this.$emit('input', this.value)
    },
    clear () {
      this.$emit('blur', this.value)
    },
    focus () {
      this.$emit('focus', this.value)
    }
  }
}
</script>
