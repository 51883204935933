<template>
  <el-container class="container">
    <sideBar v-if="!isCrm" />
    <el-container class="container_right">
      <el-header :style="{ width: _isMobile() ? '1200px' : '' }">
        <!-- 售后服务系统 -->

        <div class="title">
          <i
            class="icon"
            :class="[
              $store.state.isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'
            ]"
            @click="collapse"
            v-if="!isCrm"
          ></i>
          <span>售后服务系统</span>
        </div>
        <div class="header_right" v-if="!isCrm">
          <div
            class="circle"
            v-show="unReadMsgList.length > 0 && unReadNewMsg"
          ></div>
          <el-popover
            v-model="showTip"
            placement="bottom-end"
            width="350"
            trigger="click"
          >
            <msgBell v-if="showTip" />
            <div class="bell" slot="reference">
              <i class="el-icon-bell"></i>
            </div>
          </el-popover>
          <el-dropdown
            v-if="loginUserInfo.storeId"
            style="margin-right:10px"
            @command="command"
          >
            <span class="el-dropdown-link">
              {{ defaultStoreName
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in storeList"
                :key="item.id"
                :command="item.storeId"
                >{{ item.storeName }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown class="avatar-container" @visible-change="OnSelect">
            <div
              class="el-dropdown-link"
              style="font-size: 16px; cursor: pointer"
            >
              <i class="el-icon-user-solid"></i>
              {{ loginUserInfo.name
              }}<i class="el-icon-arrow-down el-icon--right" ref="dropdown"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="psw" @click.native="pswShow = true"
                >修改密码</el-dropdown-item
              >
              <el-dropdown-item command="out" @click.native="logout"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <!-- 如果是手机端  就固定死宽度 -->
      <el-main :style="{ width: _isMobile() ? '1200px' : '' }">
        <breadcrumb />
        <transition name="fade-transform" mode="out-in">
          <router-view :key="key" />
        </transition>
      </el-main>
    </el-container>
    <psw :isShow="pswShow" @close="pswShow = false" />
  </el-container>
</template>
<script>
import sideBar from './sideBar'
import psw from './psw'
import msgBell from './msgBell'
import { loginOut, supplierUserList, toggleStore } from '@/api'
import { mapMutations, mapState, mapActions } from 'vuex'
import chatMixin from '@/mixin/chat-mixin'
export default {
  name: 'layout',
  components: { sideBar, psw, msgBell },
  mixins: [chatMixin],
  data () {
    return {
      pswShow: false,
      showTip: false,
      storeList: []
    }
  },
  computed: {
    ...mapState(['loginUserInfo', 'defaultStoreName']),
    ...mapState('im', ['unReadMsgList', 'unReadNewMsg']),
    key () {
      return this.$route.path
    },
    // 是否是crm系统跳转过来
    isCrm () {
      return this.$route.path === '/crm'
    }
  },
  mounted () {
    this.loginUserInfo.storeId && this.getStoreList()
    this.getUnReadMsgList()
    // 刷新页面重新登录im
    !this.isCrm && this.imLogin()
  },
  methods: {
    ...mapMutations([
      'SET_MENU_LIST',
      'SET_LOGIN_USERINFO',
      'SET_COLLAPSE',
      'SET_DEFAULT_STORENAME'
    ]),
    ...mapMutations('im', ['changeUnReadMsgList', 'changeUnReadNewMsg']),
    ...mapActions('im', ['imLogout', 'getUnReadMsgList', 'imLogin']),
    // 获取当前商家用户对于多个公司
    getStoreList () {
      const params = {
        userId: this.loginUserInfo.id
      }
      supplierUserList(params).then(res => {
        const list = res.data.list
        this.storeList = list
        this.getDefaultStoreName()
      })
    },
    // 获取当前登录的商家名
    getDefaultStoreName () {
      const defaultStoreName = this.storeList.find(
        item => item.storeId === this.loginUserInfo.storeId
      ).storeName
      this.SET_DEFAULT_STORENAME(defaultStoreName || '')
    },
    // 切换商家
    getToggleStore (storeId) {
      toggleStore({ storeId }).then(res => {
        const { loginUser, permission } = res.data
        this.SET_MENU_LIST(permission)
        this.SET_LOGIN_USERINFO(loginUser)
        this.getDefaultStoreName()
        window.location.reload() // 切换商家刷新页面
      })
    },
    command (val) {
      this.getToggleStore(val)
    },
    // 判断是否是手机端
    _isMobile () {
      const flag = !!navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    logout () {
      const Authorization = localStorage.getItem('token')
      loginOut({ Authorization }).then(res => {
        if (res.code === '1') {
          this.$message.success('退出成功')
          localStorage.clear()
          this.$router.push('/login')
          this.imLogout()
        }
      })
    },
    OnSelect (val) {
      this.$refs.dropdown.style.transition = 'all 0.3s'
      if (val) {
        this.$refs.dropdown.style.transform = 'rotate(-180deg)'
      } else {
        this.$refs.dropdown.style.transform = 'rotate(0deg)'
      }
    },
    // 伸缩和展开侧边栏
    collapse () {
      // this.$store.commit('SET_COLLAPSE')
      this.SET_COLLAPSE()
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 16px;
  span {
    position: relative;
    top: -4px;
    left: 4px;
  }
  .icon {
    font-size: 26px;
    cursor: pointer;
  }
}
.header_right {
  display: flex;
  .circle {
    position: absolute;
    right: 130px;
    top: 16px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
  }
  .bell {
    position: relative;
    top: 3px;
    width: 20px;
    height: 33px;
    margin-right: 10px;
    cursor: pointer;
    .el-icon-bell {
      font-size: 18px;
    }
  }
}
</style>
