// ====================工单管理==================
export default {
  path: '/work-order',
  name: 'work-order',
  meta: {
    title: '工单管理'
  },
  component: () =>
    import(
      /* webpackChunkName: "work-order" */ '@/views/work-order'
    ),
  children: [
    {
      path: '/work-order/follow',
      name: 'work-order-follow',
      meta: {
        title: '跟进工单'
      },
      component: () =>
        import(
          /* webpackChunkName: "follow" */ '@/views/work-order/follow'
        )
    },
    {
      path: '/work-order/history',
      name: 'work-order-history',
      meta: {
        title: '历史工单'
      },
      component: () =>
        import(
          /* webpackChunkName: "history" */ '@/views/work-order/history'
        )
    },
    {
      path: '/work-order/service',
      name: 'service-handle',
      meta: {
        title: '客服处理'
      },
      component: () =>
        import(
          /* webpackChunkName: "service-handle" */ '@/views/work-order/service-handle'
        )
    },
    {
      path: '/work-order/afterSale',
      name: 'afterSale-handle',
      meta: {
        title: '售后处理'
      },
      component: () =>
        import(
          /* webpackChunkName: "afterSale-handle" */ '@/views/work-order/afterSale-handle'
        )
    },
    {
      path: '/work-order/merchant',
      name: 'merchant-handle',
      meta: {
        title: '商家处理'
      },
      component: () =>
        import(
          /* webpackChunkName: "merchant-handle" */ '@/views/work-order/merchant-handle'
        )
    },
    {
      path: '/work-order/dispatch',
      name: 'dispatch-handle',
      meta: {
        title: '调度处理'
      },
      component: () =>
        import(
          /* webpackChunkName: "dispatch-handle" */ '@/views/work-order/dispatch-handle'
        )
    },
    {
      path: '/work-order/scene',
      name: 'scene-handle',
      meta: {
        title: '上门处理'
      },
      component: () =>
        import(
          /* webpackChunkName: "scene-handle" */ '@/views/work-order/scene-handle'
        )
    },
    {
      path: '/work-order/visit',
      name: 'service-visit',
      meta: {
        title: '客服回访'
      },
      component: () =>
        import(
          /* webpackChunkName: "service-visit" */ '@/views/work-order/service-visit'
        )
    },
    {
      path: '/work-order/sales',
      name: 'sales-see',
      meta: {
        title: '销售查单'
      },
      component: () =>
        import(
          /* webpackChunkName: "sales-see" */ '@/views/work-order/sales-see'
        )
    },
    {
      path: '/work-order/group-chat',
      name: 'group-chat',
      meta: {
        title: '群聊管理'
      },
      component: () =>
        import(
          /* webpackChunkName: "group-chat" */ '@/views/work-order/group-chat'
        )
    },
    // 用于其他页面跳转(备用)
    {
      path: '/work-order/all-order-detail',
      name: 'all-order-detail',
      meta: {
        title: '工单详情'
      },
      component: () =>
        import(
          /* webpackChunkName: "all-order-detail" */ '@/views/work-order/all-order/detailCon'
        )
    }

  ]
}
