// ====================供应商==================
export default {
  path: '/supplier',
  name: 'supplier',
  meta: {
    title: '供应商'
  },
  component: () =>
    import(
      /* webpackChunkName: "supplier" */ '@/views/supplier'
    ),
  children: [
    {
      path: '/supplier/list',
      name: 'supplier-list',
      meta: {
        title: '商家列表'
      },
      component: () =>
        import(
          /* webpackChunkName: "supplier-list" */ '@/views/supplier/list'
        )
    },
    {
      path: '/supplier/manage',
      name: 'supplier-manage',
      meta: {
        title: '商家管理'
      },
      component: () =>
        import(
          /* webpackChunkName: "supplier-manage" */ '@/views/supplier/manage'
        ),
      children: [
        {
          path: '/supplier/manage/detail',
          name: 'supplier-detail',
          meta: {
            title: '商家详情',
            activeMenu: '/supplier/manage'
          },
          component: () =>
            import(
              /* webpackChunkName: "supplier-detail" */ '@/views/supplier/manage/detail'
            )
        }
      ]
    }
  ]
}
