//  格式化千分符
export function millimeter (str) {
  return (
    str &&
    str.toString().replace(/\d{1,3}(?=(\d{3})+$)/g, content => {
      return content + ','
    })
  )
}
// 角色转中文
export function handleRoleName (str) {
  switch (str) {
    case 'aftermarket':
      return '售后'
    case 'service':
      return '客服'
    case 'dispatch':
      return '调度'
    default:
      return '售后'
  }
}
