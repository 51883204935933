// ====================财务结算==================
export default {
  path: '/financial',
  name: 'financial-settlement',
  meta: {
    title: '财务结算'
  },
  component: () =>
    import(
      /* webpackChunkName: "financial-settlement" */ '@/views/financial-settlement'
    ),
  children: [
    {
      path: '/financial/stat-pay',
      name: 'stat-pay',
      meta: {
        title: '服务站付款账单'
      },
      component: () =>
        import(
          /* webpackChunkName: "stat-pay" */ '@/views/financial-settlement/stat-pay'
        ),
      children: [
        {
          path: '/financial/stat-pay/detail',
          name: 'stat-pay-detail',
          meta: {
            title: '工单付款明细',
            activeMenu: '/financial/stat-pay'
          },
          component: () =>
            import(
              /* webpackChunkName: "stat-pay-detail" */ '@/views/financial-settlement/stat-pay/detail'
            )
        }
      ]
    },
    {
      path: '/financial/store-pay',
      name: 'store-pay',
      meta: {
        title: '平台托管工单收款'
      },
      component: () =>
        import(
          /* webpackChunkName: "store-pay" */ '@/views/financial-settlement/store-pay'
        ),
      children: [
        {
          path: '/financial/store-pay/detail',
          name: 'store-pay-detail',
          meta: {
            title: '工单明细',
            activeMenu: '/financial/store-pay'
          },
          component: () =>
            import(
              /* webpackChunkName: "store-pay-detail" */ '@/views/financial-settlement/store-pay/detail'
            )
        }
      ]
    },
    {
      path: '/financial/qc-punish',
      name: 'qc-punish',
      meta: {
        title: '商家品控处罚'
      },
      component: () =>
        import(
          /* webpackChunkName: "qc-punish" */ '@/views/financial-settlement/qc-punish'
        ),
      children: [
        {
          path: '/financial/qc-punish/detail',
          name: 'qc-punish-detail',
          meta: {
            title: '工单明细',
            activeMenu: '/financial/qc-punish'
          },
          component: () =>
            import(
              /* webpackChunkName: "qc-punish-detail" */ '@/views/financial-settlement/store-pay/detail'
            )
        }
      ]
    },
    {
      path: '/financial/bill-detail',
      name: 'bill-detail',
      meta: {
        title: '发票明细'
      },
      component: () =>
        import(
          /* webpackChunkName: "bill-detail" */ '@/views/financial-settlement/bill-detail'
        )
    }
  ]
}
