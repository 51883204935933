import { getUsernameByUserid } from '@/views/work-order/all-order/about-chat/utils'
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
// import { startRecording } from '@/api'
export default {
  mounted () {
    this.initListener()
  },
  destroyed () {
    this.offListener()
  },
  computed: {
    ...mapState('trtc', ['roomID', 'callStatus', 'muteAudioUserIdList', 'meetingUserIdList']),
    ...mapGetters('im', ['groupId']),
    ...mapState('im', ['currentGroupId', 'unReadMsgList'])
  },
  watch: {
    roomID (val) {
      // 接受邀请后给后台发送请求（启动云端混流并开始录制）
      if (val) {
        // 暂时不调用
        // setTimeout(() => {
        //   startRecording({ groupId: this.groupId, roomId: val })
        // }, 1000)
      }
    }
  },
  methods: {
    ...mapMutations('im', ['pushCurrentMessageList', 'changeNewMsgGroup', 'changeUnReadNewMsg']),
    ...mapMutations('trtc', ['dissolveMeeting', 'changeShowVideoCallDialog', 'changeShowNewInvitationDialog', 'userLeaveMeeting', 'userAccepted', 'userJoinMeeting', 'updateCallStatus', 'updateMuteVideoUserIdList']),
    ...mapActions('im', ['getUnReadMsgList']),
    // =================视频通话事件检测===============
    initListener () {
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      // this.tim.on(this.TIM.EVENT.SDK_READY, this.onReadyStateUpdate)
      // 收到新消息
      this.tim.on(this.TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage)
      // 收到视频通话邀请
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.INVITED,
        this.handleNewInvitationReceived
      )
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.USER_ACCEPT,
        this.handleUserAccept
      )
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.USER_ENTER,
        this.handleUserEnter
      )
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.USER_LEAVE,
        this.handleUserLeave
      )
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.REJECT,
        this.handleInviteeReject
      )
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.LINE_BUSY,
        this.handleInviteeLineBusy
      )
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.CALLING_CANCEL,
        this.handleInviterCancel
      )
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.CALLING_TIMEOUT,
        this.handleCallTimeout
      )
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.NO_RESP,
        this.handleNoResponse
      )
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.CALLING_END,
        this.handleCallEnd
      )
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.USER_VIDEO_AVAILABLE,
        this.handleUserVideoChange
      )
    },
    offListener () {
      // this.tim.off(this.TIM.EVENT.SDK_READY, this.onReadyStateUpdate)
      this.tim.off(this.TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage)
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.INVITED,
        this.handleNewInvitationReceived
      )
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.USER_ACCEPT,
        this.handleUserAccept
      )
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.USER_ENTER,
        this.handleUserEnter
      )
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.USER_LEAVE,
        this.handleUserLeave
      )
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.REJECT,
        this.handleInviteeReject
      )
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.LINE_BUSY,
        this.handleInviteeLineBusy
      )
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.CALLING_CANCEL,
        this.handleInviterCancel
      )
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.CALLING_TIMEOUT,
        this.handleCallTimeout
      )
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.NO_RESP,
        this.handleNoResponse
      )
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.USER_VIDEO_AVAILABLE,
        this.handleUserVideoChange
      )
    },
    // 收到新消息
    onReceiveMessage ({ data: messageList }) {
      if (messageList[0].payload.data) {
        // 收到群视频信息
        const obj1 = JSON.parse(messageList[0].payload.data)
        // console.log('============', obj1)
        const obj2 = JSON.parse(obj1.data)
        if (obj2.call_type === 2 && obj1.actionType === 1) {
          // this.videoGroupId = obj1.groupID
          // this.hintShow = true
          this.$message({
            message: `群聊${obj1.groupID}有客户请求视频通话,请到群聊管理中找对应的群聊`,
            type: 'warning',
            duration: 5000
          })
        }
      }
      if (this.currentGroupId != messageList[0].to) {
        this.changeUnReadNewMsg(true)
        this.changeNewMsgGroup(messageList[0].to)
        if (this.unReadMsgList.length === 0) {
          this.getUnReadMsgList()
        }
      }
      // 过滤掉 视频通话的im信息
      messageList = messageList.filter(item => item.type !== 'TIMCustomElem')
      this.pushCurrentMessageList(messageList)
      this.$bus.$emit('scrollMessageListToButtom')
    },
    handleNewInvitationReceived (payload) {
      this.$bus.$emit('handleNewInvitationReceived', payload)
    },
    handleUserAccept ({ userID }) {
      this.userAccepted(true)
    },
    handleUserEnter ({ userID }) {
      // 建立连接
      this.userJoinMeeting(userID)
      if (this.callStatus === 'calling') {
        // 如果是邀请者, 则建立连接
        this.$nextTick(() => {
          // 需要先等远程用户 id 的节点渲染到 dom 上
          this.updateCallStatus('connected')
        })
      } else {
        // 第n (n >= 3)个人被邀请入会, 并且他不是第 n 个人的邀请人
        this.$nextTick(() => {
          // 需要先等远程用户 id 的节点渲染到 dom 上
          this.trtcCalling.startRemoteView({
            userID: userID,
            videoViewDomID: `video-${userID}`
          })
        })
      }
    },
    handleUserLeave ({ userID }) {
      if (this.meetingUserIdList.length == 2) {
        this.updateCallStatus('idle')
      }
      this.userLeaveMeeting(userID)
    },
    handleInviteeReject: async function ({ userID }) {
      const userName = getUsernameByUserid(userID)
      this.$message.warning(`${userName}拒绝通话`)
      if (this.meetingUserIdList.length < 2) {
        this.changeShowVideoCallDialog(false)
      }
      this.dissolveMeetingIfNeed()
    },
    handleInviteeLineBusy ({ userID }) {
      const userName = getUsernameByUserid(userID)
      this.$message.warning(`${userName}忙线`)
      this.dissolveMeetingIfNeed()
    },
    handleInviterCancel: function () {
      // 邀请被取消
      this.changeShowNewInvitationDialog(false)
      this.$message.warning('通话已取消')
      this.dissolveMeetingIfNeed()
    },
    // 作为被邀请方会收到，收到该回调说明本次通话超时未应答
    handleCallTimeout: function () {
      this.changeShowNewInvitationDialog(false)
      this.$message.warning('通话超时未应答')
      this.dissolveMeetingIfNeed()
    },
    handleNoResponse ({ userID }) {
      const userName = getUsernameByUserid(userID)
      this.$message.warning(`${userName}无应答`)
      this.dissolveMeetingIfNeed()
    },
    handleCallEnd: function () {
      this.$message.success('通话已结束')
      this.changeShowVideoCallDialog(false)
      this.trtcCalling.hangup()
      this.dissolveMeetingIfNeed()
      this.userAccepted(false)
    },
    handleUserVideoChange ({ userID, isVideoAvailable }) {
      if (isVideoAvailable) {
        const muteUserList = this.muteAudioUserIdList.filter(
          currentID => currentID !== userID
        )
        this.updateMuteVideoUserIdList(muteUserList)
      } else {
        const muteUserList = this.muteAudioUserIdList.concat(userID)
        this.updateMuteVideoUserIdList(muteUserList)
      }
    },
    dissolveMeetingIfNeed () {
      this.updateCallStatus('idle')
      this.changeShowNewInvitationDialog(false)
      if (this.meetingUserIdList.length < 2) {
        this.dissolveMeeting()
      }
    }
  }
}
