export default {
  namespaced: true,
  state: {
    isLogin: false,
    loginUserInfo: null,
    // trtc 相关
    isShowVideoCallDialog: false, // 是否显示视频区域弹窗
    isShowVideoCall: false, // 是否显示视频区域
    isShowNewInvitationDialog: false, // 被邀请人收要邀请弹窗
    callStatus: '', // 状态, idle, calling, connected
    inviterId: '', // 邀请人的id
    roomID: '', // 房间id
    isInviter: false, // c2c 通话，说不定一开始不是 inviter, 后面邀请了别人就是 inviter 了
    isAccepted: false,
    meetingUserIdList: [],
    muteVideoUserIdList: [],
    muteAudioUserIdList: []
  },
  mutations: {
    changeRoomID (state, id) {
      state.roomID = id
    },
    changeInviterId (state, id) {
      state.changeInviterId = id
    },
    changeShowVideoCallDialog (state, bool) {
      state.isShowVideoCallDialog = bool
    },
    changeShowNewInvitationDialog (state, bool) {
      state.isShowNewInvitationDialog = bool
    },
    changeShowVideoCall (state, bool) {
      state.isShowVideoCall = bool
    },
    userLoginSuccess (state) {
      state.isLogin = true
    },
    userLogoutSuccess (state) {
      state.isLogin = false
      state.loginUserInfo = null
    },
    setLoginUserInfo (state, payload) {
      const { userId, userSig } = payload
      state.loginUserInfo = {
        userId, userSig
      }
    },
    updateIsInviter (state, isInviter) {
      state.isInviter = isInviter
    },
    updateCallStatus (state, callStatus) {
      state.callStatus = callStatus
    },
    userJoinMeeting (state, userId) {
      if (state.meetingUserIdList.indexOf(userId) === -1) {
        state.meetingUserIdList.push(userId)
      }
    },
    userAccepted (state, isAccepted) {
      state.isAccepted = isAccepted
    },
    userLeaveMeeting (state, userId) {
      const index = state.meetingUserIdList.findIndex(item => item === userId)
      if (index >= 0) {
        state.meetingUserIdList.splice(index, 1)
      }
    },
    dissolveMeeting (state) {
      state.meetingUserIdList = []
      state.isMuteVideoUserIdList = []
      state.isMuteAudioUserIdList = []
    },
    updateMuteVideoUserIdList (state, userIdList) {
      state.muteVideoUserIdList = userIdList
    },
    updateMuteAudioUserIdList (state, userIdList) {
      state.muteAudioUserIdList = userIdList
    }
  }
}
