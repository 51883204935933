import http from '../request/http'
import QS from 'qs'
// 获取genUserSig
export function getUserSig (params) {
  return http.post(`/wxApi/rtc/genUserSig?${QS.stringify(params)}`)
}
// 点击进入聊天按钮获取群组信息
export function enterChat (params) {
  return http.post(`/wxApi/imGroup/enterWebImGroup?${QS.stringify(params)}`)
}
// 群聊发送消息
export function sendGroupMsg (params) {
  return http.post('/wxApi/imGroup/sendGroupMsg', params)
}
// 群聊删除消息
export function delHistoryMsg (params) {
  return http.post(`/wxApi/imGroup/delImGroupChatLog?${QS.stringify(params)}`)
}
// 启动云端混流并开始录制
export function startRecording (params) {
  return http.post(
    `/wxApi/rtc/startMCUMixTranscodeByStrRoomId?${QS.stringify(params)}`
  )
}
// 群聊列表
export function groupChatList (params) {
  return http.post('/wxApi/imGroup/getGroupList', params)
}
// 群聊列表
export function groupChatHistoryMsg (params) {
  return http.post(`/wxApi/imGroup/getGroupMsg?${QS.stringify(params)}`)
}
// 未读消息列表
export function unReadMessageList (params) {
  return http.post(`/wxApi/imGroup/getUnReadMessage?${QS.stringify(params)}`)
}
// 解散群组
export function destroyGroup (params) {
  return http.get('/wxApi/imGroup/destroyGroup', { params })
}
