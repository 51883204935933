import http from '../request/http'
import QS from 'qs'
// ============事件管理=============
// 告警事件
// 列表
export function eventList (params) {
  return http.post('/mgr/faultIncident/list', params)
}
// 编辑
export function eventEdit (params) {
  return http.post('/mgr/faultIncident/update', params)
}
// 中止
export function eventOver (params) {
  return http.post(`/mgr/faultIncident/delete?${QS.stringify(params)}`)
}
// 领取
export function eventReceive (params) {
  return http.post(`/mgr/faultIncident/receive?${QS.stringify(params)}`)
}
// 转交
export function eventTransfer (params) {
  return http.post(`/mgr/faultIncident/changeHandler?${QS.stringify(params)}`)
}
// 处理工单
export function handleOrderList (params) {
  return http.post(`/mgr/postSaleOrder/listByFaultIncidentId?${QS.stringify(params)}`)
}
