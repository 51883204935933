import Vue from 'vue'
/**
 *
 * @param {*} menuList 从store里取得菜单列表
 * @param {*} currentPath 当前路由地址
 * @param {*} btnName 当前按钮的名字
 * @returns 是否包含当前的按钮名字
 */
export function btnPermission (menuList, currentPath, btnName) {
  let flag = false
  let current = {}
  let btnNameArr = []
  for (let i = 0; i < menuList.length; i++) {
    const list = menuList[i].children
    if (list) {
      for (let j = 0; j < list.length; j++) {
        if (list[j].label === currentPath) {
          flag = true
          current = list[j]
          break
        } else {
          flag = false
        }
      }
      if (flag) break
    }
  }
  btnNameArr = current.children && current.children.map(item => item.name)
  return btnNameArr && btnNameArr.includes(btnName)
}
function directiveFn (el, binding, vNode) {
  const flag = btnPermission(vNode.context.$store.state.menuList, vNode.context.$route.path, binding.value)
  localStorage.setItem('btnNameFlag', flag) // 做个标识
  if (!flag) {
    el.style.display = 'none'
  }
}
Vue.directive('permission', directiveFn)
