// ====================系统管理==================
export default {
  path: '/system',
  name: 'system-manage',
  meta: {
    title: '系统管理'
  },
  component: () =>
    import(
      /* webpackChunkName: "system-manage" */ '@/views/system-manage'
    ),
  children: [
    {
      path: '/system/menu',
      name: 'menu-manage',
      meta: {
        title: '菜单管理'
      },
      component: () =>
        import(
          /* webpackChunkName: "menu-manage" */ '@/views/system-manage/menu-manage'
        )
    },
    {
      path: '/system/roles',
      name: 'roles-manage',
      meta: {
        title: '角色管理'
      },
      component: () =>
        import(
          /* webpackChunkName: "roles-manage" */ '@/views/system-manage/roles-manage'
        )
    },
    {
      path: '/system/user',
      name: 'user-manage',
      meta: {
        title: '人员管理'
      },
      component: () =>
        import(
          /* webpackChunkName: "user-manage" */ '@/views/system-manage/user-manage'
        )
    },
    {
      path: '/system/dict',
      name: 'params-dict',
      meta: {
        title: '参数字典'
      },
      component: () =>
        import(
          /* webpackChunkName: "params-dict" */ '@/views/system-manage/params-dict'
        ),
      children: [
        {
          path: '/system/params-dict/detail',
          name: 'params-dict-detail',
          meta: {
            title: '参数值',
            activeMenu: '/system/dict'
          },
          component: () =>
            import(
              /* webpackChunkName: "params-dict-detail" */ '@/views/system-manage/params-dict/detail'
            )
        }
      ]
    },
    {
      path: '/system/device',
      name: 'device-manage',
      meta: {
        title: '设备故障'
      },
      component: () =>
        import(
          /* webpackChunkName: "device-manage" */ '@/views/system-manage/device-manage/index'
        ),
      children: [
        {
          path: '/system/device/child',
          name: 'child-fault',
          meta: {
            title: '子故障',
            activeMenu: '/system/device'
          },
          component: () =>
            import(
              /* webpackChunkName: "child-fault" */ '@/views/system-manage/device-manage/childFault'
            )
        }
      ]
    },
    {
      path: '/system/duty',
      name: 'sale-duty',
      meta: {
        title: '售后值班'
      },
      component: () =>
        import(
          /* webpackChunkName: "sale-duty" */ '@/views/system-manage/sale-duty'
        )
    }
  ]
}
