
<template>
  <el-select
    @change="change"
    @input="input"
     @clear="clear"
    :multiple="multiple"
    collapse-tags
    :filterable="isfilterable"
    v-model="value"
    :clearable="clearable"
    :placeholder="placeholder"
    :size="size"
  >
    <el-option key="" value="" label="全部" v-if="isShowAll"></el-option>
      <el-option
        v-for="item in data"
        :key="item[optionsParams.value]"
        :label="item[optionsParams.label]"
        :value="item[optionsParams.value]"
      ></el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    // 可以配置label  value
    optionsParams: {
      type: Object,
      default: () => ({ value: 'value', label: 'label' })
    },
    // 是否支持模糊查询
    isfilterable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    isShowAll: {
      type: Boolean,
      default: false
    },
    // 传入数据
    data: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    hval: {
      type: [String, Number, Boolean]
    },
    size: {
      type: String,
      default: 'small'
    }

  },
  data () {
    return {
      value: ''
    }
  },
  watch: {
    hval: {
      immediate: true,
      handler (val) {
        this.value = val
      }
    }
  },
  mounted () {},
  methods: {
    change () {
      this.$emit('change', this.value)
    },
    clear () {
      this.$emit('change', this.value)
    },
    input () {
      this.$emit('input', this.value)
    }
  }
}
</script>
