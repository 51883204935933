import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import echarts from 'echarts'
import axios from 'axios'
import VueClipboard from 'vue-clipboard2' // 用于复制内容的剪贴板
import elementResizeDetectorMaker from 'element-resize-detector' // 图表窗口自适应
import * as filters from '@/utils/globalFilters'
import groupComp from '@/components/common'
import tim from './tim'
import TIM from 'tim-js-sdk'
import trtcCalling from './trtc-calling'
import TRTCCalling from 'trtc-calling-js'
import './utils/directive'
import '@/utils/elementui'
import '@/style/index.scss'
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios
Vue.prototype.tim = tim
Vue.prototype.TIM = TIM
Vue.prototype.trtcCalling = trtcCalling
Vue.prototype.TRTCCalling = TRTCCalling

Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。
Vue.config.productionTip = false
Vue.use(groupComp)

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
// 注册全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
// 图表宽度根据窗口自适应
function erd (id, echartsId) {
  return elementResizeDetectorMaker().listenTo(
    document.getElementById(id),
    () => {
      if (Object.prototype.toString.call(echartsId) === '[object Array]') {
        this.$nextTick(() => {
          echartsId.forEach(i => {
            this.$echarts.init(document.getElementById(i)).resize()
          })
        })
      } else {
        this.$nextTick(() => {
          this.$echarts.init(document.getElementById(echartsId)).resize()
        })
      }
    }
  )
}
Vue.prototype.$erd = erd

const whiteList = ['/login', '/crm']
// 路由守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token') || ''
  if (token) {
    if (to.path === '/' || to.path === '/login') {
      if (!store.state.menuList.length) {
        // 缓存中没有菜单数据
        localStorage.removeItem('token')
        next('/login')
      } else {
        next({ path: store.state.menuList[0].children[0].label })
      }
    } else {
      next()
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/login')
    }
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
