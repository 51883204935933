// ====================服务站==================
export default {
  path: '/service-station',
  name: 'service-station',
  meta: {
    title: '服务站'
  },
  component: () =>
    import(
      /* webpackChunkName: "station-info" */ '@/views/service-station'
    ),
  children: [
    {
      path: '/service-station/station-info',
      name: 'station-info',
      meta: {
        title: '站点列表'
      },
      component: () =>
        import(
          /* webpackChunkName: "station-info" */ '@/views/service-station/station-info'
        ),
      children: [
        {
          path: '/service-station/station-detail',
          name: 'station-detail',
          meta: {
            title: '服务站详情',
            activeMenu: '/service-station/station-info'
          },
          component: () =>
            import(
              /* webpackChunkName: "station-detail" */ '@/views/service-station/station-info/detail'
            )
        }
      ]
    },
    {
      path: '/service-station/secen-cost',
      name: 'secen-cost',
      meta: {
        title: '上门成本审核'
      },
      component: () =>
        import(
          /* webpackChunkName: "secen-cost" */ '@/views/service-station/secen-cost'
        )
    }
  ]
}
