<template>
  <el-button
    :type="btnType"
    @click="click"
    :loading="loading"
    :plain="plain"
    :size="size"
    :round="round"
    :disabled="disabled"
    :icon="icon"
    :circle="circle"
  >
    <slot></slot>
  </el-button>
</template>
<script>
export default {
  props: {
    // 按钮类型 primary / success / warning / danger / info / text
    btnType: {
      type: String,
      default: 'primary'
    },
    // 按钮上显示加载状态。
    loading: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'small'
    },
    icon: {
      type: String,
      default: ''
    },
    plain: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>
