// ====================数据中心==================
export default {
  path: '/data-center',
  meta: {
    title: '数据中心'
  },
  component: () =>
    import(
      /* webpackChunkName: "data-center" */ '@/views/data-center'
    ),
  children: [
    {
      path: '/data-center/map',
      meta: {
        title: '城市看板'
      },
      component: () =>
        import(
          /* webpackChunkName: "data-center-map" */ '@/views/data-center/map'
        )
    },
    {
      path: '/data-center/rank',
      meta: {
        title: '售后单王'
      },
      component: () =>
        import(
          /* webpackChunkName: "sale-rank" */ '@/views/data-center/sale-rank'
        )
    },
    {
      path: '/data-center/allAging',
      meta: {
        title: '工单总时效'
      },
      component: () =>
        import(
          /* webpackChunkName: "allAging" */ '@/views/data-center/all-aging'
        )
    },
    {
      path: '/data-center/aging',
      meta: {
        title: '工单时效'
      },
      component: () =>
        import(
          /* webpackChunkName: "aging" */ '@/views/data-center/aging'
        )
    },
    {
      path: '/data-center/device',
      meta: {
        title: '报障量统计'
      },
      component: () =>
        import(
          /* webpackChunkName: "data-center-device" */ '@/views/data-center/device'
        )
    },
    {
      path: '/data-center/sku',
      meta: {
        title: 'SKU故障分析'
      },
      component: () =>
        import(
          /* webpackChunkName: "sku" */ '@/views/data-center/sku'
        )
    },
    {
      path: '/data-center/fault',
      meta: {
        title: 'SKU发货质量'
      },
      component: () =>
        import(
          /* webpackChunkName: "fault" */ '@/views/data-center/fault'
        )
    },
    {
      path: '/data-center/fault-cycle',
      meta: {
        title: '报障周期分析'
      },
      component: () =>
        import(
          /* webpackChunkName: "fault-cycle" */ '@/views/data-center/fault-cycle'
        )
    },
    {
      path: '/data-center/rent-withdrawal',
      meta: {
        title: '月度新增退租'
      },
      component: () =>
        import(
          /* webpackChunkName: "rent-withdrawal" */ '@/views/data-center/rent-withdrawal'
        )
    },
    {
      path: '/data-center/customer-chargeback',
      meta: {
        title: '客户退单统计'
      },
      component: () =>
        import(
          /* webpackChunkName: "customer-chargeback" */ '@/views/data-center/customer-chargeback'
        ),
      children: [
        {
          path: '/data-center/customer-chargeback/reason',
          name: 'customer-chargeback-reason',
          meta: {
            title: '退机原因',
            activeMenu: '/data-center/customer-chargeback'
          },
          component: () =>
            import(
              /* webpackChunkName: "customer-chargeback-reason" */ '@/views/data-center/customer-chargeback/reason'
            )
        },
        {
          path: '/data-center/customer-chargeback/model',
          name: 'customer-chargeback-model',
          meta: {
            title: '退机机型',
            activeMenu: '/data-center/customer-chargeback'
          },
          component: () =>
            import(
              /* webpackChunkName: "customer-chargeback-model" */ '@/views/data-center/customer-chargeback/modelNum'
            )
        },
        {
          path: '/data-center/customer-chargeback/duration',
          name: 'customer-chargeback-duration',
          meta: {
            title: '退机时长',
            activeMenu: '/data-center/customer-chargeback'
          },
          component: () =>
            import(
              /* webpackChunkName: "customer-chargeback-duration" */ '@/views/data-center/customer-chargeback/duration'
            )
        },
        {
          path: '/data-center/customer-chargeback/table',
          name: 'customer-chargeback-table',
          meta: {
            title: '表格视图',
            activeMenu: '/data-center/customer-chargeback'
          },
          component: () =>
            import(
              /* webpackChunkName: "customer-chargeback-table" */ '@/views/data-center/customer-chargeback/table'
            )
        }
      ]
    },
    {
      path: '/data-center/city-unpack',
      meta: {
        // title: '开箱不良图表'
        title: '城市开箱不良'
      },
      component: () =>
        import(
          /* webpackChunkName: "city-unpack" */ '@/views/data-center/city-unpack'
        ),
      children: [
        {
          path: '/data-center/city-unpack/analy',
          name: 'city-unpack-analy',
          meta: {
            title: '问题分析',
            activeMenu: '/data-center/city-unpack'
          },
          component: () =>
            import(
              /* webpackChunkName: "city-unpack-analy" */ '@/views/data-center/city-unpack/analyDetail'
            )
        }
      ]
    },
    {
      path: '/data-center/store-unpack',
      meta: {
        // title: '开箱不良统计'
        title: ' 商家开箱不良'
      },
      component: () =>
        import(
          /* webpackChunkName: "store-unpack" */ '@/views/data-center/store-unpack'
        ),
      children: [
        {
          path: '/data-center/store-unpack/analy',
          name: 'store-unpack-analy',
          meta: {
            title: '问题分析',
            activeMenu: '/data-center/store-unpack'
          },
          component: () =>
            import(
              /* webpackChunkName: "store-unpack-analy" */ '@/views/data-center/store-unpack/analy'
            )
        },
        {
          path: '/data-center/store-unpack/sku',
          name: 'store-unpack-sku',
          meta: {
            title: 'sku统计',
            activeMenu: '/data-center/store-unpack'
          },
          component: () =>
            import(
              /* webpackChunkName: "store-unpack-sku" */ '@/views/data-center/store-unpack/sku'
            )
        },
        {
          path: '/data-center/store-unpack/detail',
          name: 'store-unpack-detail',
          meta: {
            title: '发货明细',
            activeMenu: '/data-center/store-unpack'
          },
          component: () =>
            import(
              /* webpackChunkName: "delivery-detail" */ '@/views/data-center/store-unpack/detailed'
            )
        }
      ]
    },
    {
      path: '/data-center/delivery-unpack',
      meta: {
        title: '订单开箱不良'
      },
      component: () =>
        import(
          /* webpackChunkName: "delivery-unpack" */ '@/views/data-center/delivery-unpack'
        )
    },
    {
      path: '/data-center/customer-unpack',
      name: 'customer-unpack',
      meta: {
        title: '客户开箱不良'
      },
      component: () =>
        import(
          /* webpackChunkName: "customer-unpack" */ '@/views/data-center/customer-unpack'
        ),
      children: [
        {
          path: '/data-center/customer-unpack/detail',
          name: 'customer-unpack-detail',
          meta: {
            title: '发货明细',
            activeMenu: '/data-center/customer-unpack'
          },
          component: () =>
            import(
              /* webpackChunkName: "customer-unpack-detail" */ '@/views/data-center/customer-unpack/detail'
            )
        }
      ]
    }
  ]
}
